.login-bg {
    background: transparent;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;   
    background-repeat: no-repeat;
    background-position: center top !important;
    @include media-breakpoint-up(md) {
        .xZ2Xk{
            display: none;
        }
    }
    
    @include media-breakpoint-down(sm) {
        background-image:none !important;
        min-height: 100vh;
        .xZ2Xk{
            bottom: 0;
            left: 0;
            position: fixed;
            text-align: center;
            width: 100%;
            height: 70px;
            z-index: 4;
            .overlay{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 15px 0px 0 5px;
                border-top: 3px solid #ffcc00;
                background-color: white;
                opacity: 0.9;
            }
         
            .text{
                font-weight: 500;
                width: 60%;
                float: left;
                line-height: 1.3;
                font-size: 13px;
            }
            .button-block{
                float: left;
                width: 39%;
                img.close{
                    width: 16px;
                    float: right;
                    margin: 13px 7px 0;
                }
                .button{
                    width: auto;
                    margin: 5px 0 0 5px;
                    height: 30px;
                    padding: 0 6px;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 35px;
                    border-radius: 7px !important;
                    background: #00142D;
                    color: #fff;
                    float: right;
                }
            }
        }
    }
}

.white-logo {
    background-repeat: no-repeat;
    background-position: top right;
    background-image: url("");
    @include media-breakpoint-down(sm) {
        height: 185px;
        width: 335px;
        background-size: 207px;
        background-image: url("../Assets/Images/sun-login-mobile.png");
    }
    &.mobie-image{
        height: 195px;
        width: 335px;
        background-size: 209px;
        background-image: url("../Assets/Images/sun-login-mobile.png");
    }
}


.center-white-box {
    width: 390px;
    padding: $padd44;
    background: white;
    border-radius: 12px;
    margin: 0px 0 30px 0;
    padding: $padd44 $padd48 $padd55 $padd48;
    @include media-breakpoint-up(sm){
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
    }

    @include media-breakpoint-down(sm) {
        margin: -5px 0 $marg10;
        padding: $padd20;
        padding-top: 0;
        background-color: transparent !important;
		width: 300px;
        &.box-Shadow{
            border: none;
            box-shadow: none !important;
        }
    }
    h2 {
        font-size: $font20;
        font-weight: 500;
        display: inline-block;
        margin: $marg0;
    }
    .modal-footer {
        border: 0px;
        padding: $padd0;
    }
    .modal-body {
        padding: $padd40;
    }
    form {
        margin-top: $marg30;
    }
    
    h2 {
        font-size: $font20;
        font-weight: 500;
        display: inline-block;
        margin: $marg0;
    }
    .modal-footer {
        border: 0px;
        padding: $padd0;
    }
    .modal-body {
        padding: $padd40;
    }
}

.login-box {
    display: flex;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    h2 {
        font-weight: 400 !important;
    }
    .ant-input-password{
        padding: 0;
        border: none;
    }
        #exampleInputPassword1,#exampleInputEmail1{
            box-shadow: 0px 0px 0px 50px white inset !important;
            padding-left:0 ;
            font-size: 14px !important;
            @include media-breakpoint-down(sm) {
            text-transform: none;
            box-shadow: 0px 0px 0px 50px #F5F8FB inset !important;
        }
    }
    .form-control::placeholder,#exampleInputPassword1::placeholder,#exampleInputEmail1 { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: black;
        opacity: 1; /* Firefox */
        font-size: 14px;
    }

    .form-control:-ms-input-placeholder,#exampleInputPassword1:-ms-input-placeholder,#exampleInputEmail1 { /* Internet Explorer 10-11 */
        color: black;
    }

    .form-control::-ms-input-placeholder,#exampleInputPassword1::-ms-input-placeholder,#exampleInputEmail1 { /* Microsoft Edge */
        color: black;
        font-size: 14px;
    }
}



.logo-blue {
    background: no-repeat center center/ contain;
    max-width: 175px;
    height: 35px;
    height: 35px;
    margin: $marg20 0 $marg20 0;
    background-position: left;
    &.condition{
        margin: 10px auto 30px;
        height: 45px
    }
    @include media-breakpoint-down(sm) {
        max-width: 175px;
        width: auto;
        background: no-repeat left center/ contain;
        background-image: url(../Assets/Images/bodhi-logo-png-001@3x.png) ;

    }

}



.forgot {
    color: #05bfff;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
		display: block;
        padding-top:0;
	}
}

#forgotpassword .modal-header {
    border: 0px;
}


.login-footer {
    padding: $padd20 0 $padd30 0;
}

.login-footer .powered {
    opacity: .7;
}

/* 28 nov 18 */


.center-white-box .welcome {
    margin: 0px;
}

.center-white-box label {
    margin-bottom: 0px;
    display: block;
}

#reset-password-cont .forgotpassword .form-group {
    margin-bottom: $marg10;
}

.center-white-box a {
    color: #05bfff !important;
    font-size: 12px;
}

.center-white-box small {
    color: black;
    font-size: 0.875rem;
    font-weight: 300;
    margin-bottom: 15px !important;
    display: block;
}

.login-remeber {
    margin-left: $marg0;
    margin-top: $marg35;

    .check-wrapper {
        display: block;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .container {
            padding-left: 27px;
        }
        .check-wrapper input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }

  
    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: #eee;
      border-radius: 2px;
      &:after {
          content: '';
          position: absolute;
          display: none;
        }
    }

  
    .check-wrapper input:checked ~ .checkmark:after {
      display: block;
    }
  
    .check-wrapper .checkmark:after {
        left: 7px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
  
}

.error {
    color: $error;
}

.error h2 {
    font-size: $font20;
    margin-bottom: $marg0;
}