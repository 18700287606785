.referral-wrap {
    &.mapMargin{
        background: #ffffff;
        // margin-top: 70px;
    }
    
    .map-wrapper {
        height: 582px;
        width: 100%;
        position: relative;

        @include media-breakpoint-down(sm) {
            height: 350px;
        }
    }

    // public referral page sccs
    .referral-content {
        position: relative;
        background: #fff;
        top: -230px;
        max-width: 845px;
        text-align: center;
        padding: 0;
        border: 1px solid lightgray;
        box-sizing: border-box;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            top: 0px;
            margin: 0 0 25px;
            width: 100%;
            padding: 10px 10px 0;
            border: 0;
        }

        .row {
            margin: 0 0 10px;
            .padding-offset {
                padding: 0 !important;
            }
        }

        .referral-heading {
            width: 100%;
            padding: 25px 0;
            color: #fff;
            margin: 0 0 30px;
            font-weight: 700;
            letter-spacing: .99px;
            line-height: 44px;

            @include media-breakpoint-down(sm) {
                background-color: transparent;
                color: #000;
                font-size: 18px;
                margin-bottom: 0px;
                padding: 0px;
                margin-top: 25px;
            }
        }
        // left section of referral landing page
        p.referral-homeowner {
            color: #000000;
            
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.33px;
            line-height: 24px;
            margin-bottom: 30px;
            text-align: justify;
            padding: 0 13px;
            &.companyInfo {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }

        p.join_solar_header {
            width: 100%;
            color: #4A4A4A;
            font-weight: bold;
            letter-spacing: 0.12px;
            line-height: 20px;
            text-align: left;
            margin: 0;
            padding: 0 8px;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        // right section of referral landing page
        .calc-gap {
            padding-left: 60px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                margin-bottom: 0px;
                padding: 0 15px;
            }

          
            .enter-your-ad {
                height: 20px;
                width: 100%;
                color: #4A4A4A;
                font-weight: 300;
                letter-spacing: 0.26px;
                line-height: 20px;
                &.glance {
                    margin: 15px 0px 15px;
                }
                &.rating{
                    margin-top: 25px;
                }
            }

            .ant-slider-mark {
                font-size: 10px;
                .ant-slider-mark-text.ant-slider-mark-text-active:first-child {
                    padding-left: 15px;
                }
            }
        }
    }

    // public glance page css
    .system-glance-box {
        .elctric-img,.sun-img{
            width: 30px;
        }
        .solar-img{
            width: 51px;
        }
        .icon {
            min-height: 40px;
            .calculatedIcon {
                height: 35px;
            }
            margin: 10px 0 $marg15 0;
            @include media-breakpoint-down(xs) {
                margin-top:$marg25
            }
        }
        .kw {
            opacity: 0.9;
            color: #000000;
            
            font-size: 18px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
        }
        .desc {
            color: #000000;
            
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.25px;
            line-height: 20px;
            text-align: center;
        }
    }
    

    .explore-savings {
        height: 24px;
        color: #000000;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.33px;
        line-height: 24px;

        &.sunburst{
            margin-top: 10px;
            @include media-breakpoint-down(sm) {
                color: #000000;
            }
        }
        @media all and (max-width:767px) {
            text-align: center;
        }
    }

    .row.glance {
        margin: 0;


        .systemGlance-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 312px;
            margin: 0 auto;

            @include media-breakpoint-down(sm) {
                margin-top: 305px;
            }

            

            .systemGlance-heading {
                color: #FFCC00;
                
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0.25px;
                line-height: 44px;
                text-align: center;
                margin-top: 50px;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            h4 {
                
                font-size: 18px;
                letter-spacing: 0.33px;
                line-height: 28px;
                text-align: justify;
                color: #000000;
                margin-top: 5px;
            }


            p {
                height: 130px;
                width: 310px;
                color: #000000;
                
                font-weight: 300;
                letter-spacing: 0.26px;
                line-height: 20px;
                text-align: justify;
                margin-bottom: 30px;
                letter-spacing: 0.3px;
                font-size: 15px;
                margin-bottom: 20px;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 35px;
                }
            }
        }
        .monitoring-mobile-view-only{
            display: none;
            @include media-breakpoint-down(sm) {
                display: block;
                border: 0px solid #E0E0E0;
                border-radius: 4px;
                padding: 0 15px;
                &.slider{
                    width: calc(100% - 18px);
                    margin: 35px 0px;
                }
                .referral-heading-sunburst{
                    margin: 0;
                    font-size: 18px;
                    padding: 0px 0 15px;
                    font-weight: bold;
                }
                .referralLanding-content{
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 24px;
                    text-align: justify;
                    letter-spacing: 0.26px;
                }
            }
        }

        .monitoring-bg {
            @include media-breakpoint-down(sm) {
                text-align: center
            }

            img{
                width: 112px;
                height: 43px;
                margin-top: 22px;
                margin-left: 40px;
                @include media-breakpoint-down(sm) {
                    height: 33px;	
                    width: 71.63px;
                    margin-top: 45px;
                    margin-bottom: 15px;
                    margin-left: 0px;
            
                }
                &.desktop-img{
                    display:block;
                    @include media-breakpoint-down(sm) {
                        display:none;
                    }
                }
                &.mobile-img{
                    display:none;
                    @include media-breakpoint-down(sm) {
                        display:block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
            .monitoring-content {
                min-height: auto;
                width: 430px;
                text-align: center;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 0 auto;
                h4{
                    font-size: 18px;
                    letter-spacing: 0.33px;
                    line-height: 28px;
                    text-align: justify;
                    margin-top: 35px;
                    margin-bottom: 16px;
                }
                p {
                    font-size: 18px;
                    letter-spacing: 0.33px;
                    line-height: 28px;
                    text-align: justify;
                }
                .monitoring-form {
                    width: 100%;
                    text-align: left;
                    margin-top: 30px;
                ::-webkit-input-placeholder {
                     color: #fff;
                 }

                :-ms-input-placeholder {
                    color: #fff;
                }

                ::placeholder {
                    color: #fff;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 45px;
                    ::placeholder {
                        color: initial;
                    }
                }
                .enter-your-ad.glance{
                    margin: 15px 0px 15px;
                    &.rating{
                        margin-top: 25px;
                    }
                }

                input {
                    background-color: transparent !important;
                    @include media-breakpoint-down(sm) {
                        color: #9B9B9B;
                        }
                    }
                }
            }
            .monitoring-heading {
                font-size: 34px;
                font-weight: bold;
                letter-spacing: 1.25px;
                line-height: 44px;
                text-align: center;
                margin-top: 35px;
                &.glance{
                    font-size: 30px;
                    margin-bottom: 20px;	
                }
                @include media-breakpoint-down(sm) {
                    display: none;
                }
                .mobile-view-only{
                    @include media-breakpoint-down(sm) {
                        font-size: 14px;	
                        letter-spacing: 0.25px;	
                        line-height: 20px; 
                        font-weight: normal; 
                        margin-top: 6px; 
                        width:100%; 
                        text-align: center;    
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .ant-slider-mark-text{
            &.ant-slider-mark-text-active:first-child {
                padding-left: 15px;
            }
            .sunburst-slider{
                color: white;
                font-size: initial;
                line-height: initial;
                font-size: 13px;
                &.mobile-color{
                    @include media-breakpoint-down(sm) {
                        color: #00142D;
                    }
                }
            }
        }
        .monitorignGraph {
            background: #fff;
            min-height: 768px;
            position: relative;
            height: auto;
            @include media-breakpoint-down(sm) {
                min-height: 520px;
                border: 0px solid #E0E0E0;
                border-radius: 4px;
            }
        }
    }
    
    .referral-submit-bookMeeting {
        margin: 15px 0 45px;
    }

}

.add-utility.popUp{
    .ant-modal-close{
        display: none;
    }
    .ant-modal-body{
        padding: 30px 0px 10px;
    }
    .add-signer {
        text-align: left !important;
        color: #00142D;
        font-size: 14px;
        line-height: 16px;
        width: 100%;
        margin-left: 30px;
        margin-top: 5px;
        margin-bottom: 23px;
        position: relative;
        align-items: end;
        cursor: pointer;
        .plus {
            margin-right: 0px;
        }
        .text{
            padding-left: 5px;
        }
    }
}

.thank-you.popUp {
    width: 695px !important;
    padding: 60px 70px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #E9E9E9;
    text-align: center;
    .ant-modal-close{
        display: none;
    }
    h2 {
      font-size: 26px;
      line-height: 32px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      width: 96%;
      margin: 20px auto 0 0;
    }
    .thanks-btn {
      margin-top: 30px;
      width: 158px;
      height: 30px;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.26px;
      line-height: 18px;
      background-color: #FFCC00;
      border-radius: 2px;
      color: white;
    }
}

.notice-box {
    background-color: #e9e9e9;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    line-height: 20px;
    color: #4a4a4a;
    padding: 6px 20px 1px 20px;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    text-align: justify;
    display: none;
    .chrome-icon{
        height: 15px;
        width: 5px;
    }
    .revert{
        transform: rotate(90deg);
        margin: 0 8px;
        width: 7px;
    }
    .opera{
        height: 22px;
        width: 22px;
        transform: rotate3d(1, 1, 1, 350deg);
        margin: 0;
    }
    .close-icon{
        font-size: 25px;
    }
    .button-popUp{
        color: #00142D ;
    }
    .star-icon{
        color: #00142D;
        font-size: 18px;
        margin: 0 3px;
    }
    @include media-breakpoint-down(md) {
        display: block;
    }
}

.add-screen-popup{
    .ant-modal{
        top: 0;
        max-width: 100%;
        margin: 0;
        .add-home-screen-view {
            font-size: 14px;
            line-height: 26px;
            color: #000000;
            background-color: white;
            padding: 30px;
            .bodhi-icon{
                height: 21px;
                width: 20.5px;
                margin: 0px 4px;
                border-radius: 50%;
            }
            .chrome-icon{
                height: 15px;
                width: 5px;
            }
            .revert{
                height: 15px;
                width: 5px;
                transform: rotate(90deg);
                margin: 0 8px;
            }
            .homeScreenMenu img{
                width:  300px;
            }
            .homeScreenOption img{
                width: 200px;
            }
            h2 {
              font-size: 24px;
              line-height: 28px;
              font-weight: 800;
              text-align: center;
            }
            .close-icon {
              margin-bottom: 20px;
              color: #000000;
              font-size: 20px;
              font-weight: 500;
            }
            .got-it-btn {
              width: 120px;
              margin: 0 auto;
              margin-top: 30px;
              div {
                width: 120px;
                background-color: #ffcc00;
                height: 30px;
                font-size: 12px;
                line-height: 14px;
                display: block;
                text-align: center;
                padding-top: 7px;
                font-weight: 600;
                color: white;
              }
            }
        }
    }
}

@media all and (display-mode: standalone) {
    .xZ2Xk{
        display: none;
        background-color: red;
    }
    .notice-box{
        display: none;
    }
}
