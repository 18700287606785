.create-campaign-wrapper {
	margin: 150px 0;
	.container {
		.steps-wrapper {
			text-align: center;
			width: 100%;
			color: #9b9b9b;
			font-size: 14px;
			line-height: 16px;
			ul {
				margin: 0;
				padding: 0;
				text-align: center;
				li {
					display: inline-block;
					list-style: none;
					color: #9b9b9b;
					margin-right: 30px;
					span {
						color: #9b9b9b;
						position: relative;
						font-weight: 600;
						&::before {
							position: absolute;
							width: 10px;
							height: 10px;
							content: "";
							top: 4px;
							left: -20px;
							background-color: #9b9b9b;
							border-radius: 50%;
						}
					}
					span.active {
						&::before {
							background-color: #00142D;
						}
						color: #00142D;
						span {
							background: #f5f8fb;
						}
					}

					span.step-2 {
						&::after {
							position: absolute;
							content: "";
							width: 109px;
							height: 1px;
							background-color: #9b9b9b;
							top: 8px;
							left: -140px;
						}
						&.active {
							&::after {
								background-color: #00142D;
							}
						}
					}
					span.step-3 {
						&::after {
							position: absolute;
							content: "";
							width: 109px;
							height: 1px;
							background-color: #9b9b9b;
							top: 8px;
							left: -140px;
						}
						&.active {
							&::after {
								background-color: #00142D;
							}
						}
					}
				}
				li.stepli-2 {
					padding-left: 120px;
				}
				li.stepli-3 {
					padding-left: 120px;
				}
			}
		}
		.white-box {
			background: #ffffff;
			border: 1px solid #e9e9e9;
			padding: 14px 25px;
			color: #4a4a4a;
			line-height: 16px;
			font-size: 14px;
			text-align: center;
			&.mt-4 {
				margin-top: 32px !important;
				padding: 0;
				flex-direction: column;
				text-align: left;
				padding: 32px 60px 30px 118px;
				.footer-btn-box{
					margin-top: 90px;
				}
				.del-btn {
					text-align: right;
					display: block;
					color: #9b9b9b;
					font-size: 14px;
					line-height: 16px;
					cursor: pointer;
					float: right;
					img {
						width: 14px;
						vertical-align: middle;
					}
				}
				h2 {
					color: #4a4a4a;
					font-size: 30px;
					line-height: 35px;
					width: 100%;
					font-weight: 700;
					margin-top: 20px;
				}
				p.sub-heading {
					font-size: 14px;
					line-height: 16px;
					color: #9b9b9b;
					font-weight: 700;
				}
				.form-wrapper {
					margin-top: 30px;
					.review-title{
						color: #6a6a6a !important;
    					font-size: 18px;
    					line-height: 20px;
						font-weight: 700;
						margin-bottom: 18px;
					}
					.off-heading{
						color: #4A4A4A;
						font-size: 18px;
						font-weight: 300;
						line-height: 21px;
						margin: 10px 0 36px;
					}
					label {
						color: #4a4a4a;
						font-size: 18px;
						line-height: 21px;
						display: inline-block;
						margin-bottom: .5rem;
						font-weight: 300;
					}
					.form-group {
						margin-top: 46px;
						.length-text{
							font-size: 12px;
    						font-weight: 300;
    						margin-top: 5px;
						}
						.total-filter{
							font-size: 18px;
							font-weight: 300;
							margin-top: 10px;
						}
						.checkbox-wrapper {
							display: flex;
							.on-off-case {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								margin-right: 15px;
								margin-top: 13px;
								font-weight: 300;
								button.ant-switch{
									margin-right: 10px;
									
								}
							}
						}
						.form-control {
							border: 0;
							border-radius: 0;
							border-bottom: 1px solid #d8d8d8;
							font-size: 14px;
							line-height: 16px;
							padding-left: 0;
							color: #4a4a4a;
						}
						.form-control.textareabox {
							border: 1px solid #d8d8d8;
							padding: 15px;
						}
						input{
							text-transform: none !important;
						}
						textarea::placeholder {
							font-size: 14px;
							font-weight: 300;
						}
					}
					.post-preview {
						.left-heading {
							font-size: 18px;
							line-height: 21px;
							color: #4a4a4a !important;
							margin-bottom: 15px;
							font-weight: 700;
						}
						.m-0 {
							margin: 0;
							padding: 0;
						}
						.media {
							padding: 20px 20px 0;
							text-align: left;
							.profile-image {
								width: 40px;
								height: 40px;
								border-radius: 50%;				
								h5 {
									font-size: 1.125rem;
									color: rgba(0, 0, 0, 0.87);
									margin-bottom: 0;
									letter-spacing: 0.23px;
									line-height: 21px;
									margin-bottom: 5px;
									text-align: left;
								}
					
								p {
									font-size: 0.875rem;
									color: rgba(0, 0, 0, 0.87);
									margin-bottom: 5px;
									font-weight: 400;
									text-align: left;
								}
							}
					
							.media-body{
								text-transform: capitalize;
								p.text{
									line-height: 1.5;
									letter-spacing: 0.3px;
									font-size: .875rem;
									color: rgba(0,0,0,.87);
									margin-bottom: 5px;
									font-weight: 400;
									text-transform: capitalize;
								}
							} 
						}
					
						.hut-cross {
							position: absolute;
							z-index: 2;
							font-size: 25px;
							right: 15px;
							color: black;
						}
					}
					
				}
			}
		}
	}
	
}

.add-recipents-wrapper {
	select {
		font-size: 14px;
		border: 1px solid #e9e9e9;
		-webkit-appearance: none;
		color: #4a4a4a;
		-moz-appearance: none;
		appearance: none;
		// background: url(../../bodhi/images/img/grey-down-arrow.svg) 96% / 15% no-repeat;
		background-size: 10px 10px;
		min-width: 200px;
		&::-ms-expand {
			display: none;
		}
	}
	padding-left: 15px;
	h3 {
		color: #6a6a6a !important;
		font-size: 18px;
		line-height: 20px;
		font-weight: 600;
		width: auto;
		display: inline-block;
		margin-right: 10px;
	}
	.off-heading {
		color: #4a4a4a;
		font-size: 18px;
		line-height: 21px;
		margin-bottom: 56px;
		margin-top: 10px;
		width: 100%;
	}
	.fillter-wrapper {
		width: 81%;
		.and-row{
			margin: 10px 0;
			color: #00142D;
		}
		.close-btn-wrapp {
			position: relative;
			margin-bottom: 15px;
			width: 90%;
			padding-left: 15px !important;
			.close-btn {
				position: absolute;
				top: 10px;
				right: -30px;
				color: #4a4a4a;
				font-size: 12px;
				cursor: pointer;
			}
		}
		.form-control {
			width: auto;
		}
		.form-group {
			margin: 0 20px 0 0 !important;
			min-width: 200px;
			.value-plus {
				position: relative;
				.value-block{
					position: relative;
				}
				.plus-icon {
					position: absolute;
					top: 10px;
					right: 0;
					font-size: 16px;
					color: #4a4a4a;
				}
			}
		}
		.add-new-campaign {
			margin-top: 25px;
			padding: 0 15px 0 15px;
			color: #00142D;
			cursor: pointer;
		}
		.recipients-box {
			color: #00142D;
			font-size: 18px;
			line-height: 21px;
			margin-top: 35px;
		}
	}
}


.popUp.campaign-success .ant-modal-footer{
	.ant-btn{
		display: none;
		&.ant-btn-primary{
			display: block;
			margin: 0 auto;
		}
	}
}

.campaign-scheduled.ant-calendar-picker .anticon-close-circle{
	display: none !important;
}

.campaignPreview.left-content-box {
	text-align: center;
	h3 {
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.36px;
		font-weight: 600;
		margin: 0px 10px 20px 10px;
	}
	p {
		padding: 0 20px;
		font-size: 14px;
		text-align: left;
		padding-bottom: 0;
		line-height: 20px;
		margin-bottom: 60px;
		&.homeowner{
			margin-bottom: 25px;
		}
	}
	.get-button {
		margin-bottom: 90px;
		margin-top: 35px;
		font-weight: 700;
		&.homeowner{
			margin-bottom: 0px;
			margin-top: 0px;
		}
	}
}

.hut-img {
	width: 99%;
	// height: 217px;
	background-size: cover;
	margin: 2px 2px 25px;
	position: relative;
}

.campaign-file-upload{
	position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
	.text{
		margin-bottom: 10px;
    	font-size: 15px;
    	color: #4A4A4A;
	}
}

.upload-file-wrap {
	padding: 2px;
	margin-bottom: 15px;
	
	input[type="file"] {
		display: none;
	}

	.custom-file-upload {
		border: 1px solid #e9e9e9;
		display: inline-block;
		padding: 6px 12px;
		cursor: pointer;
		background: #f4f7fa;
		width: 100%;
		min-height: 170px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 14px !important;
		font-weight: 400 !important;
		img {
			width: 32px;
			margin-bottom: 5px;
		}

		span {
			display: block;
			line-height: 20px;
			width: 290px;
			margin: 0 auto;
		}
	}
}