.camp-dashboard-review-wrapper {
    margin: 150px 0 150px 0;
    .back-btn{
        color: #9b9b9b;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 36px;
        display: block;
        cursor: pointer;
    }
	h1 {
		color: #00142D;
		font-size: 30px;
		line-height: 40px;
		font-weight: 600;
    }
    .camp-dash-content-box {
        padding-right: 120px;
        margin-bottom: 20px;
        .text{
            min-width: 60px;
        }
        .camp-detail-box {
            padding-left: 100px;
            h2 {
                padding-left: 16px;
                margin-bottom: 30px;
                color: #00142D;
                font-size: 30px;
                line-height: 40px;
                font-weight: 800;
                width: 100%;
            }
            .name-adddres-box {
                .detail-box {
                    p {
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        line-height: 22px;
                        color: #4a4a4a;
                        font-weight: 600;
                    }
                }
            }
        }
    }
	.personal-detail-box {
		.detail-box {
            margin-bottom: 20px;
			p {
				margin: 0;
				padding: 0;
				font-size: 16px;
				line-height: 15px;
				color: #4a4a4a;
				&:nth-child(1) {
                    font-weight: 600;
                    margin-bottom: 15px;
				}
				&:nth-child(2) {
                    font-weight: 400;
                    div{
                        line-height: 20px;
                    }
				}
			}
		}
		position: relative;
		padding-left: 30px;
		min-height: 125px;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			height: 125px;
			width: 1px;
			background: #979797;
			content: "";
		}
    }
    .media {
        padding: 20px 25px;
        h5 {
            font-size: 1.125rem;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 0;
            letter-spacing: 0.23px;
            line-height: 21px;
            margin-bottom: 5px;
            text-align: left;
            text-transform: capitalize;;
        }
        p {
            font-size: 0.875rem;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 5px;
            font-weight: 400;
            text-align: left;
        }
        .profile-image{
            height: 100%;
            width: 100%;
        }
        .media-body{
            padding-left: 15px;
        }
    }

}