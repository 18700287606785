*,
button:focus {
	outline: none;
	box-shadow: none !important;
}

.ant-checkbox-wrapper{
	color: #000;
}

.search-wrap .ant-select-selector{
	border: 0 none!important;
	background-color: transparent;
}

.ant-menu-item >span >div, .ant-menu-submenu span >div {
	display: inline-flex;
}

.dev-login-form {
	max-width: 300px;
}

.dev-login-form-forgot {
	float: right;
}

.dev-login-form-button {
	width: 100%;
}

.dev-terms-and-conditions {
	width: 169px;
	height: 20px;
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	color: rgba(0, 0, 0, 0.87);
	letter-spacing: normal;
	text-align: justify;
}

.terms-and-condition-heading {
	object-fit: contain;
	font-size: 14px;
	font-weight: 400 !important;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: 0.3px;
	color: rgba(0, 0, 0, 0.87);
	margin: 0 auto 12px !important;
	float: none;
	font-weight: bold;
	text-align: center;
}

.terms-and-condition {
	font-size: 12px !important;
	font-weight: 300 !important
}

.privacy-and-policy-heading {
	object-fit: contain;
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: 0.3px;
	color: rgba(0, 0, 0, 0.87);
	margin: 0 auto 12px !important;
	float: none;
	font-weight: bold;
	text-align: center;
}

.dev-center-white-box {
	position: relative;
}

.dev-close-btn {
	position: absolute;
	top: -10px;
	right: -6px;
	background: #d8d8d8;
	color: black;
	font-size: 14px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 9;
}

.ant-input {
	border: none !important;
	border-bottom: 1px solid #d9d9d9 !important;
	border-radius: 0px !important;
	padding: 4px 0px;
}

@font-face {
	font-family: 'proxima_nova_rgregular';
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}


body {
	margin: 0;
	font-size: $font-size-base;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background: #fff !important;
	background-size: cover !important;
	@include media-breakpoint-down(md) {
		background-color: #fff !important;
	}
	&.arial{
		font-family: 'Arial', Helvetica, sans-serif;
	}
	&.source{
		font-family: 'Source Sans Pro', sans-serif!important;
	}
	&.rubik{
		font-family: 'Rubik', sans-serif !important;
	}
	&.raleway{
		font-family: 'Raleway', sans-serif !important;
	}
	&.questrial{
		font-family: 'Questrial', sans-serif !important;
	}
	&.poppins{
		font-family: 'Poppins', sans-serif !important;
	}
	&.montserrat{
		font-family: 'Montserrat', sans-serif !important;
	}
	&.lato{
		font-family: 'Lato', sans-serif !important;
	}
	&.kulim{
		font-family: 'Kulim Park', sans-serif !important;
	}
	&.cabin{
		font-family: 'Cabin', sans-serif !important;
	}
	&.harmonia{
		font-family: 'HarmoniaSansProCyrRegular'!important;                                        
	}
	&.gothic{
		font-family: 'Century Gothic', sans-serif !important;                                        
	}
	// &.futura{
	// 	font-family: 'Futura Std' !important;
	// }
	&.proxima{
		font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif !important;
	}
	&.kulim{
		font-family: 'Kulim Park', sans-serif !important;
	}
	&.muli{
		font-family: "muli",sans-serif; 
	}
}

#body.bg-color {	
	background-color: #F5F8FB !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.create-new-account-wrapper,
.new-client-form-wrapper {
	width: 778px;
    margin: 73px auto 80px;
	padding-top: 90px!important;
	@include media-breakpoint-down(sm) {
		padding-top: 5px !important;
		width: 100%;
	}
}

.ant-carousel .slick-slide {
	text-align: center;
	height: 160px;
	line-height: 160px;
	background: #364d79;
	overflow: hidden;
}

.ant-carousel .slick-slide h3 {
	color: #fff;
}

textarea.ant-input{
	padding: 4px 10px;
}

.ant-select-selection {
	border: none !important;
	border: 1px solid #d9d9d9 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
}

.ant-select-selection--multiple{
	min-height: auto;
	padding-bottom: 0;
}

.ant-calendar-picker-clear{
	display: none;
}


.report{
	width: 100%;
	.ant-select-auto-complete.ant-select .ant-select-selection{
		border: none !important;
	}
}
.overflow-hidden {
	overflow: hidden;
}

.termsCondition {
	position: relative;
	width: 580px;
	transform: translate(0px, 30px);
	margin-top: 60px;
    bottom: 10px;
}

.ant-input:hover {
	box-shadow: none !important;
	border-color: black  !important;
}

.ant-input:focus {
	box-shadow: none !important;
	border-color: #FFD110  !important;
}


.drop-icon {
	margin-right: 10px;
	&.homeowner{
		position: relative;
		width: auto;
		float: left;
		margin-top: 20px;
	}
}
.dashboard-nav-wrapper .bg-light {
	background-color: #fff !important;
    border-bottom:1px solid #dfe6ee ;
}
.margin-t-73 {
	margin-top: 73px;
}

.margin-t-120 {
	margin-top: 120px;
}

.margin-t-73-mobile {
	@include media-breakpoint-down(sm) {
		margin-top: 76px;
	}
}

.forgotpassword {
	@include media-breakpoint-down(sm) {
	}
}

.padding-left-10px {
	@include media-breakpoint-down(sm) {
		padding-left: 10px !important;
	}
}

.ant-form input[type="file"] {
	height: 100%;
}

.searchId {
	width: 10px;
	visibility: hidden;
	display: inline-block;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
	padding: 0 0px;
	height: 0;
	line-height: 0;
	font-size: 12px;
}

.search-btn {
	z-index: 99;
}

.addHomeowner{
	.ant-select-search__field{
		width: 100% !important;
		border: none !important;
	}
}

form .ant-cascader-picker {
	width: 60px;
}




.detail-info-box .form-control {
	border-bottom: 0;
}

h3 {
	color: rgba(0, 0, 0, 0.85) !important;
}



.media-body a {
	width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
	/* background: red; */
	display: block;
	color: #4A4A4A;
	font-size: 14px;
	font-weight: 400;
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.media-body h5+a {
	font-weight: 300;
}

.rating-box+.heading {
	font-size: 18px;
	font-weight: 300;
	line-height: 21px;
	@include media-breakpoint-down(sm) {
		vertical-align: middle;
		line-height: 90px;
		text-align: left;
	}
}

.has-error .ant-form-explain,
.has-error .ant-form-split,
.has-success.has-feedback .ant-form-item-children-icon {
	display: none !important;
}



.ant-form-item-children {
	position: relative;
	display: inline-block;
	width: 100%;
}

.ant-form-explain {
	display: none !important;
}

.faq-add-btn {
	border: 0 !important;
	box-shadow: inherit !important;
	color: #ff6f47;
	font-size: $font14;
	margin-top: 20px;
	&:hover,&:active,&:focus{
		color: #ff6f47;
	}
}

.faq-setting-wrap {
	margin-top: 10px;
	padding-top: 0px;
	.faq-form{
		padding: 13px 20px 0;
		border-bottom: 1px solid lightgray;
		.faq-textarea{
			width: 90%;
			margin-bottom: 20px;
		}
	}
}

.question-ans-box {
	font-weight: 300;
	vertical-align: top;
	padding-right: 9px;
}

.ques-ans-box {
	padding: 13px 20px 0;
    position: relative;
    display: flow-root;
	border-bottom: 1px solid lightgray;
	
	.box {
		margin-bottom: $marg10;
		float: left;
    	width: 90%;
    	position: relative;
		a {
			padding-right: $padd30;
			color: $labelcolor;
			font-size: $font18;
		}
		textarea {
			height: 47px;
			min-height: 47px;
			max-height: 2105px;
			width: 94%;
			resize: none;
			margin-bottom: 0px;
			padding-top: 0px;
			font-weight: 300;
			border: none !important;
			color: black;
			@include media-breakpoint-down(sm) {
				width: 91%;
			}
		}
	&.ans{
		margin-bottom : 0
	}
   }
   &:last-child {
		border-bottom: 0;
	}
	.faq-action-btn {
		position: relative;
		.fa-pencil{
			margin: 0 10px;
		}
	}
}

.detail-info .form-wrap .form-group textarea {
	margin-bottom: -8px;
	padding: 14px 23px;
	@include media-breakpoint-down(xs) {
		margin-bottom: 0;
	}
}

form .has-feedback>.ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-selection-selected-value {
	padding-right: 15px !important;
	font-size: 14px;
    color: #000000;
    font-weight: 300;
}

div.rc-virtual-list {
	margin-top: 0px;
	div.ant-select-item-option-content {
		font-weight: 300;
		&:hover:not(.ant-select-dropdown-menu-item-disabled){
			background-color: #e3e3e3;
		}
	}
}

.ant-select-dropdown-hidden {
	z-index: 9;
}

.ant-select-dropdown.certain-category-search-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft {
	z-index: 999 !important;
	@include media-breakpoint-down(md) {
		top: 194px !important;
	}
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
div.ant-select-item-option-content:hover:not(.ant-select-dropdown-menu-item-disabled){
	background-color: #F4F7FA !important;
}

.ant-input {
	color: #000 !important;
}

.ant-form-item {
	margin-bottom: 0 !important;
}

.ant-form {
	font-family: inherit !important;
	color: black;
}

.font-size16 {
	font-size: 16px;
}

.ant-form-item-children i {
	margin-bottom: 24px !important;
}

.employee-account-info .check-wrapper {
	width: 20px;
	margin: auto;
}

.check-wrapper .container {
	padding-left: 30px;
}

.check-wrapper input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

form input[type="radio"],
form input[type="checkbox"] {
	width: 21px;
	height: 21px;
	z-index: 9999;
	top: 0;
	left: 0;
}

.check-wrapper .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.check-wrapper input~.checkmark:after {
	display: none;
}

.check-wrapper input:checked~.checkmark:after {
	display: block;
}

.pm-home-owner-wrapper .nav-tabs .nav-item:last-child .nav-link {
	border-right: 0;
}

.terms-and-condition-check{
    margin-left: 5px;
	&:hover {
		cursor: pointer;
		text-decoration: underline !important;
	}
}

.hidden {
	opacity: 0.5;
}

.show {
	opacity: 1;
}

.detail-info .form-wrap .form-group .textarea-none textarea {
	border: none !important;
	padding-top: 0;
	padding-bottom: 0;
	resize: none;
	pointer-events: none;
}

div.rc-virtual-list div.ant-select-item ant-select-item-option.ant-select-item-option-active {
	padding-top: 0;
    padding-bottom: 0;
	margin-top: 0;
    margin-bottom: 0;	
}

div.rc-virtual-list div.ant-select-item ant-select-item-option.ant-select-item-option-active div.ant-select-item-option-content p.certain-search-item-count:first-letter {
	text-transform: capitalize;
}

.ant-select-dropdown.certain-category-search-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft p.certain-search-item-count:first-letter {
	text-transform: capitalize;
	display: initial;
	margin: 0;
	padding: 0;
	display: inline-block;
}

.ant-select-dropdown.certain-category-search-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft p.certain-search-item-count {
	display: initial;
	margin: 0;
	padding: 0;
	display: inline-block;
}

.change-pwd-section {
	margin-top: 30px;
	.notify-bell{
		width: 30px;
	}
}

.section-from-box {
	border-bottom: 0 ;
}

.border-none, .comapny-detail-box.border-none
{
	border: 0 !important;
}
.add-existing-installer {
	background: url(./../Assets/Images/existing-install.svg) no-repeat 0 0;
	height: 91px;
	width: 128px;
}

.existing-insall-box:hover .add-existing-installer {
	background: url(./../Assets/Images/existing-install-hover_yellow.svg) no-repeat 0 0;
	height: 91px;
	width: 128px;
}

.add-existing-installer h4 {
	margin-top: 169px;
}

.existing-insall-box h4 {
	margin-top: 32px;
	cursor: pointer;
}

.existing-insall-box:hover h4 {
	color: #ffcc00;
	cursor: pointer;
}

.add-new-installer {
	background: url(./../Assets/Images/add_person_yellow.svg) no-repeat 0 0;
	height: 80px;
	width: 88px;
	margin-top: 42px;
	margin-bottom: 4px;
}

.new-install-box:hover .add-new-installer {
	background: url(./../Assets/Images/add_person_yellow_active.svg) no-repeat 0 0;
	height: 80px;
	width: 88px;
}

.add-new-installer h4 {
	margin-top: 154px;
	cursor: pointer;
}

.new-install-box:hover h4 {
	color: #ffcc00;
	cursor: pointer;
}
.homeowner-title{
    cursor: default;
}
.edit-btn {
	color: #e0e0e0;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 1.17px;
	text-align: right;
	@include media-breakpoint-down(sm) {
		float: left;
		padding-left: 12px;
		width: 100% !important;
		text-align: left;
	}
}


.acc-type-value .ant-select-selection-selected-value {
	text-align: center;
	display: block;
	width: 100%;
	line-height: 30px;
}

.sucess-btn {
	width: 158px;
	height: 30px;
}


.navbar-light .navbar-toggler {
	border-color: transparent;
}

.navbar-light .cross-icon .navbar-toggler-icon {
	background-image: url(../Assets/Images/cross.png);
	display: inline-block;
	height: 25px;
	width: 25px;
}

.navbar-light .navbar-toggler-icon {
	background-image: url(../Assets/Images/menuIcon.png);
	display: inline-block;
	height: 24px;
	width: 24px;
}

.content-center {
	justify-content: center;
}

.justify-content-center {
	display: flex;
	justify-content: center;
}

.mobile-heading-dashboard {
	display: none;
	@include media-breakpoint-down(sm) {
		display: block;
		text-align: left;
		font-size: 1rem;
		margin: 20px 3px;
	}
}

#app {
	padding: 20px;
}



#chartWrapper {
	margin-top: -25px;
	svg {
		fill-opacity: 1;
	}
	path {
		stroke-width: 2px;
		fill: none;
	}
	.text{
		font-size: 13px;
		font-weight: 400;
		opacity:1 !important;
	}
}



.gridline {
	opacity: 0.2;
}

.bodhi-acc-input {
	@include media-breakpoint-down(sm) {
		margin: 0 auto;
	}
}

.text-alignment {
	display: table;
}

.text-alignment img,
.text-alignment span {
	display: table-cell;
	vertical-align: top;
}
.img-responsive{
    max-width: 100%;
    height: auto;   
}
.footer-17 {
	display: none;
	@include media-breakpoint-down(md) {
		display: flex;
        justify-content: space-around;
        align-items: center;
		bottom: 0;
		background: #fff;
		width: 100%;
		height: 75px;
		position: fixed;
		display: flex;
		justify-content: space-around;
		align-items: center;
		box-shadow: 0px 21px 67px rgba(0, 0, 0, 0.8);
        z-index: 998;
        border-top: 1px solid #E0E0E0;
		color: #D8D8D8;
        .mob-menu{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            min-width: 33%;
            span{
                width: 100%;
                text-align: center;
                margin-top: 5px; 
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
    
}


.navbar-nav {
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.menu-mobile {
	display: none;
	@include media-breakpoint-down(md) {
		display: flex;
		align-content: center;
		flex-direction: column;
		text-align: center;
		flex-direction: column;
		height: 100vh;
		z-index: 999;
	}
}

.menu-mobile .media {
	@include media-breakpoint-down(md) {
		flex-direction: column;
	}
}


.media-body a {
	@include media-breakpoint-down(md) {
		color: #4a4a4a;
	}
}

.media-body {
	@include media-breakpoint-down(md) {
		width: 100%;
		text-align: left;
	}
}

.dashboard-nav-wrapper .navbar-collapse {
	@include media-breakpoint-down(md) {
		margin-top: 0;
		position: absolute;
		top: 70px;
		left: 0;
		right: 0;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 0;
		position: absolute;
		top: 70px;
		left: 0;
		right: 0;
	}
}

.navbar-expand-lg {

    .admin-option {
		margin: 10px 5px 0;
        text-transform: capitalize;
        font-size: 14px;
		.active{
			color: #00142D !important;
    		font-weight: 700;
		}
        .admin-icon {
            margin-right: 5px;
            margin-top: 4px;
        }
    }

    button {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
            border: 0;
            background: transparent;
            position: absolute;
            right: 0;
        }
    }
}

.dashboard-nav-wrapper .dashboar-search .btn {
	display: block !important;
	border-radius: 0 !important;
}

.margin-b-20 {
	margin-bottom: 20px;
}

.search-wrapMobile {
	margin: 0 auto;
}

.btn-outline-success:focus,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
	background-color: #e9e9e9;
	border-color: #e9e9e9;
}

.map svg {
	width: 100% !important;
}

.anticon-close-circle path {
	fill: #f5222d;
}

.anticon-close-circle svg.close-circle {
	opacity: 1;
}

.dummy-img-area {
	height: 80px;
	width: 80px;
}

.margin-auto {
	margin: 0 auto;
}

.width-100 {
	width: 100%;
}

.save-setting button {
	@include media-breakpoint-down(md) {
		width: 50% !important;
	}
}

.white-box-select .ant-select {
	width: 100%;
}

button {
	border-radius: 0 !important;
}



.login-text {
	font-size: 16px;
	margin-top: 15px !important;
	width: 222px;
}

.search-wrapper {
	position: fixed;
	max-width: 100%;
}


body {
	@include media-breakpoint-down(md) {
		height: calc(100vh - 141px);
	}
}

.faq-dsiabled {
	border: none;
	background-color: #b5b5b5;
	color: #333;
	padding-top: 10px;
}

.faq-enabled {
	border: none;
	background-color: #fff;
	color: #333;
	padding-top: 10px;
	border: solid 1px #ccc;
}

.homeowner-profile .form-wrap .form-control .ant-select-selection-selected-value {
	padding-left: 0 !important;
}


.menu-mobile .media-body {
	text-align: center !important;
}

.menu-mobile .media-body a {
	width: 100% !important;
	font-size: 14px;
}

.save-setting {
	@include media-breakpoint-down(sm) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.login-remeber .col-md-6+.col-md-6 {
	@include media-breakpoint-down(sm) {
		margin-top: -5px;
	}
}

.check-wrapper .ant-checkbox-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
}

.check-wrapper .ant-checkbox-inner {
	width: 22px;
	height: 22px;
}

.check-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(50deg);
}

.ant-checkbox-checked .ant-checkbox-inner{
	background-color: #ff6f47;
    border-color: #ff6f47;
}
.ant-checkbox-checked::after,.ant-checkbox-input,.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
	border-color: #ff6f47 !important;
}

.ant-radio-checked .ant-radio-inner,.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #ff6f47;
	&::after{
		background-color:#ff6f47 ;
	}
}

.forgotpassword h2 {
	font-weight: 400;
}

.revoke-disabled {
	cursor: not-allowed;
}

.anticon.anticon-loading.ant-spin-dot svg path {
	fill: #ffcc00 !important;
	fill-opacity: 1 !important;
}

.font-weight-400 {
	font-weight: 400 !important;
}

.font-weight-300 {
	font-weight: 300 !important;
}

.font-size18 {
	font-size: $font18 !important;
	line-height: normal;
	letter-spacing: normal;
}

.margin-t-20 {
	margin-top: 20px !important;
}

.rating-box+.heading {
	font-weight: 300;
}

.disabledEmpAcc {
	background: #ededed !important;
}

.profile-setting .profile-pic.mobile-profile-pic {
	display: none;
	@include media-breakpoint-down(sm) {
		display: block;
	}
}

.profile-setting .profile-pic.desktop-profile-pic {
	display: block;
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.setting-gray {
	@include media-breakpoint-down(sm) {
		color: rgb(155, 155, 155);
		font-size: 18px;
	}
}

.border-zero {
	border: 0 !important;
}

.padding-right-zero {
	padding-right: 11px !important;
}

.overflowHidden {
	overflow: hidden !important;
}

.ant-progress-success-bg,
.ant-progress-bg {
	background-color: #ff6f47 !important;
}
a,a:hover,a:focus,span.requiredSign{
	color: #ff6f47;
}

.menu-mobile h5 {
	text-align: center;
	width: 100%;
	margin-bottom: 24px;
	font-size: 14px;
}


.desktop-view {
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.systemGlance-mobileView {
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

img {
	text-indent: -99999px;
}

.create-new-account-box>h2 {
	font-size: 14px;
}

.caret-icon {
	position: relative;
}

label.caret-icon:after {
	position: absolute;
	height: 12px;
	width: 12px;
	content: url(./../Assets/Images/path-2.svg);
	right: -20px;
	top: -3px;
}

.height-536 {
	height: 536px;
	@include media-breakpoint-down(sm) {
		height: 700px;
	}
}


.sncCRM {
	width: 100px;
	font-size: 14px;
	color: #fff;
	letter-spacing: 0.3px;
	font-weight: normal;
	border-radius: 2px;
	background-color: #00142D;
	display: inline-block;
	margin-bottom: 11px;
	cursor: pointer;
	text-align: center;
	@include media-breakpoint-down(sm) {
		float: left;
		margin-bottom: 0;
	}
}

.seeDetailsWrapper {
	color: #00142D;
}

.exisiting .margin-left-0 {
	margin-left: 0 !important;
}

.ant-notification-notice-icon-success svg {
	fill: #52c41a !important;
}

.ant-notification-notice-with-icon svg path {
	fill: inherit !important;
	fill-opacity: 1 !important;
}

.ant-notification-notice-icon-error svg {
	fill: #f5222d !important;
	fill-opacity: 1 !important;
}



input:-internal-autofill-selected {
	background-color: #fff !important;
}

.folderList ul {
	list-style-type: none;
	margin: 0 auto;
	padding: 0;
}

.folderList li a {
	color: #9b9b9b;
	font-size: 14px;
	text-decoration: none !important;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	&.drive-file{
		color: #00142D;
	}
}

.folderList ul li ul {
	text-indent: 15px;
}

.or {
	display: none;
	@include media-breakpoint-down(sm) {
		display: inline;
		font-size: 14px;
		color: #000;
		position: relative;
		background: #fff;
		width: 30px;
		letter-spacing: 0.26pt;
		font-style: italic;
	}
}

.or:after {
	content: "";
	position: absolute;
	width: 50px;
	background: #e0e0e0;
	height: 2px;
	top: 11px;
	left: 21px;
	display: inline-block;
}

.or:before {
	content: "";
	position: absolute;
	width: 50px;
	background: #e0e0e0;
	height: 2px;
	top: 11px;
	left: -61px;
	display: inline-block;
}

.section-partition {
	border-top: 1px solid #e9e9e9;
	margin-top: 7px;
	@include media-breakpoint-down(sm) {
		margin-top: 30px;
		border-top: 2px solid #e9e9e9;
	}
}

.mt-30 {
	@include media-breakpoint-down(sm) {
		margin-top: 30px !important;
	}
}

.moileAddNewHW {
	display: none;
	@include media-breakpoint-down(sm) {
		margin-top: -5px;
		display: block;
		text-align: center;
		font-size: 14pt;
		line-height: 20pt;
		letter-spacing: 0.25px;
		margin-bottom: 19px;
	}
}

.yellow-btn {
	cursor: pointer;
	border: 0px;
	width: 150px; 
	height: 40px !important;
	line-height: 40px !important;
	&:hover {
		color: white;
	}
	&:disabled {
		opacity: 1;
		cursor: not-allowed;
	}
	@include media-breakpoint-up(lg) {
		margin-top: $marg40;
	}
}



.btn:disabled {
	opacity: 1;
	cursor: not-allowed;
}

.detail-info-box label.detail-info-label {
	@include media-breakpoint-down(sm) {
		font-size: 16px !important;
		font-weight: 400 !important;
	}
}

.ant-spin-nested-loading>div>.ant-spin {
	position: fixed !important;
}
.referral-loader>.ant-spin-blur>div {
	display: none !important;
}

.disabledseedetails {
	color: #e0e0e0;
	cursor: not-allowed;
}

.employee-account-info th{
	width: 22%;
	text-align: center !important;
}

.employee-account-info td {
	width: 18%;
	text-align: center !important;
}

.employee-account-info th:first-child,
.employee-account-info td:first-child {
	padding-left: 15px;
	text-align: left !important;
}


.employee-account-info [title~="User"],
.employee-account-info button.btn-default {
	// background: #e9e9e9;
	height: 25px;
}

// .employee-account-info .ant-select-selection--single {
// 	height: 25px;
// }

.newHomeOwnerProfile label.form-group {
	@include media-breakpoint-down(sm) {
		margin-bottom: 5px;
	}
}

.newHomeOwnerProfile .mobilespacing {
	@include media-breakpoint-down(sm) {
		margin-bottom: 0px;
	}
}

.container.exisiting {
	margin-top: 70px;
}

.installer-name-or-email {
	white-space: nowrap;
	max-width: 230px;
	overflow: hidden;
	text-overflow: ellipsis;
}

html {
	scroll-behavior: smooth;
	font-size: inherit !important;
}

.css-11unzgr {
	z-index: 9;
}

.sync-wrapper {
	float: right;
	width: auto;
	@include media-breakpoint-down(sm) {
		float: left;
		width: 100%;
	}
}

.css-162g8z5 {
	background-color: transparent !important;
	border-color: transparent !important;
	min-height: 22px !important;
}

.login-footer .mobile-view {
	@include media-breakpoint-down(xs) {
		display: inline-block !important;
	}
}

.faq-box{
	margin-bottom: 20px;
	.question {
		font-weight: bold;
		margin-bottom: 5px;
	}
}

// system at a glance
.comapny-detail-box {
	font-size: $font14;
	color: black;
	float: left;
	margin-top: 13px;
	border: 1px solid #e0e0e0 !important;
	width: 100%;
	.detail-box {
		display: block;
		float: left;
		font-weight: 300;
		span:first-child {
			width: 50%;
			text-align: left;
		}
		span:last-child {
			width: 50%;
			text-align: right;
		}
		.light-blue-text {
			color: $ltblue2;
		}
	}
}


.saving-box .annual-saving-text {
	text-transform: uppercase;
	color: #000000;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.25px;
	line-height: 20px;
	text-align: center;
}

.saving-box .total-saving {
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 2.14px;
	line-height: 16px;
	text-align: center;
	margin-top: $marg10;
}



.saving-box {
	margin-top: 28px;
	border: 1px solid #e0e0e0 !important;
}

.share-via-wrapper .heading {
	font-size: $font14;
	color: $dkgrye;
	text-transform: uppercase;
	padding: $padd15;
	border: 0px;
	margin: $marg0;
	font-weight: normal;
}

.share-via-wrapper {
	display: block;
	width: 100%;
	text-align: center;
	margin-top: 13px;
	margin-bottom: 20px;
	border: 1px solid #e0e0e0;
	@include media-breakpoint-down(sm) {
		border: 1px solid #E0E0E0;
		border-radius: 4px;
		padding-bottom: 0 !important;
	}
	.heading-share {
		font-size: 15px;
		font-style: italic;
		font-weight: 300;
		letter-spacing: 0.26px;
		line-height: 16px;
		margin-top: 28px;
	}
}

.solar-community-box .share-via-wrapper {
	@include media-breakpoint-down(sm) {
		margin-top: 20px;
		border: 0 !important;
	}
}

.share-via-wrapper ul {
	padding: 0px;
	margin: 0px;
	padding-bottom: 12px;
}

.share-via-wrapper ul li {
	list-style: none;
	text-align: center;
	display: inline-block;
	line-height: 45px;
	border-right: 1px solid $ltgrey3;
	padding: 0px 16px;
	margin-bottom: 0;
	&:nth-child(2){
		@include media-breakpoint-up(lg) {
			border: 0px;
		}
	}
	&.disabled{
		display: none;
		@include media-breakpoint-down(md) {
			display: inline-block;
		}
	}
}

.share-via-wrapper ul li:last-child {
	border: 0px;
}

.map-bg .solar-community-box .share-via-wrapper {
	float: inherit;
}

.map-bg .share-via-wrapper .heading {
	display: inline;
	background: white;
	padding: 0 $padd30;
	position: relative;
	font-size: 14px;
	font-style: italic;
	font-weight: 300;
	letter-spacing: 0.26px;
	line-height: 16px;
	text-transform: lowercase;
}

.map-bg .share-via-wrapper .heading::after {
	width: 50px;
	height: 1px;
	position: absolute;
	top: 9px;
	left: -29px;
	background: $ltgrey3;
	content: "";
}

.map-bg .share-via-wrapper .heading::before {
	width: 50px;
	height: 1px;
	position: absolute;
	top: 9px;
	right: -29px;
	background: #e0e0e0;
	content: "";
}

.map-bg .share-via-wrapper ul {
	margin-top: $marg15;
}

.whtie-heading-accordion .card-header h5 a.collapsed {
	font-size: $font14;
	margin-top: 10px !important;
	display: inherit;
	text-transform: uppercase;
	font-weight: 500;
	line-height: 1.43;
	letter-spacing: 0.3px;
}




@media all and (max-width:767px) {
    .full-width{
        padding: 0;
    }
}

.next-level-wrapp .white-box {
	@include media-breakpoint-down(sm) {
		padding-bottom: 0 !important;
		margin-bottom: 12px;
	}
}

.arrow-right {
	background: url(../Assets/Images/arrow.svg);
	height: 28px;
	width: 28px;
}

.slider-wrapper {
	position: relative;
	background-repeat: no-repeat !important;
	background-size: contain !important;    
	
	.section-from-box {
		padding-bottom: 0;
	}
	.arrow-right {
		position: absolute;
		top: 50%;
		right: 3%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(0, -50%);
		display: inline-block;
		content: "";
	}
	.arrow-right.arrow-left {
		left: 3%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(0, -50%) rotate(-180deg);
	}

}
.mobile-padding-zero {
	@include media-breakpoint-down(sm) {
		padding: 0 !important;
	}
}

.document-box {
	min-height: 200px;
	height: 200px ;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	-moz-transition: background-color 0.5s ease-out;
	-o-transition: background-color 0.5s ease-out;
	transition: background-color 0.5s ease-out;
	margin-bottom: 0px;
	cursor: pointer;
	@include media-breakpoint-down(sm) {
		height: 135px !important;
		min-height: 135px;
		border-radius: 0 !important;
		margin-bottom: 0;
		padding-bottom: 0 !important;
	}
	.journey-logo{
		height:48px !important;
		width:48px !important;
		@include media-breakpoint-down(sm) {
			height:37px !important;
			width:37px !important;
		}
	}
}

.mobile-padding-zero:nth-child(even) .document-box {
	margin-left: 10px;
	@include media-breakpoint-down(sm) {
		border-right: 0 !important;
		border-top: 0;
		border-left: 0;
		margin-left: 0;
	}
}

.mobile-padding-zero:nth-child(odd) .document-box {
	margin-right: 10px;
	@include media-breakpoint-down(sm) {
		border-top: 0;
		margin-right: 0;
		border-left: 0;
	}
}

.mobile-padding-zero:nth-child(4) .document-box {
	@include media-breakpoint-down(sm) {
		border-bottom: solid 1px #c3d0dc;
	}
}

.resource-mobile-heading {
	display: none;
	@include media-breakpoint-down(sm) {
		display: block;
		font-size: 14px;
		letter-spacing: 0.25px;
		line-height: 20px;
		margin-bottom: 20px;
	}
}

.padd15 {
	padding: $padd15;
}

.document-box p {
	margin-top: $marg20;
	color: #000000;
	font-size: 18px;
	font-weight: 300;
	letter-spacing: 0.32px;
	line-height: 25px;
	text-align: center;
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		margin-top: 12px;
		letter-spacing: 0.26px;
		line-height: 20px;
	}
}



.white-box {
	border-radius: 4px;
	background-color: white;
	border: 0;
}

.docutment-row {
	margin-bottom: $marg15;
	@include media-breakpoint-down(sm) {
		border-top: solid 1px #c3d0d9;
		border-bottom: solid 1px #c3d0d9;
	}
}

.mobile-padding-zero:last-child .document-box {
	@include media-breakpoint-down(sm) {
		border-bottom: 0;
	}
}

.ho-setting-heading {
	color: rgba(0, 0, 0, 0.87);
	font-size: 14px;
	letter-spacing: 0.25px;
	line-height: 20px;
	font-weight: normal !important;
	margin-top: 13px !important;
}

.ho-setting-h4 {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: 0.3px;
	color: rgba(0, 0, 0, 0.87);
	min-height: 20px;
	@media (max-width: 767px) {
		font-size: 14px!important;	
	}
	&.glance{
		font-size: 24px;
	}
}
.ho-setting-blue-value.production-unit{
	color: #ffcc00;

}
.d3-chart-wrapper {
	margin-bottom: 43px;
	&.glance{
		width: 330px;
     	margin: 0 auto 43px;
	}
	&.referral{
		width: 100% !important;
	}
}

.ho-setting-month-style {
	
	font-size: 12px ;
	font-weight: 300 !important;
	&.glance{
		font-size: 18px ;
	}
	@media all and (max-width:767px){
		font-size: 12px !important ;
	}
}

.ho-setting-yellow-value {
	font-size: 24px;
	color: #FFD110;
	font-weight: 500;
	line-height: 30px;
	margin-top: 12px;
	&.glance{
		font-size: 36px;
	}
	@media all and (max-width:767px){
	font-size: 24px!important;
   }
}

.ho-setting-blue-value {
  
	font-size: 24px;
	font-weight: 500;
	line-height: 30px;
	margin-top: 12px;
	&.glance{
		font-size: 36px;
	}
  @include media-breakpoint-down(sm) {
    font-size: 21px!important;
	
}
}

.ho-setting-blue-value-pm {
	font-size: 14px;
}

.home-consumption {
	position: relative;
}

.home-consumption:before {
	content: "";
	position: absolute;
	width: 1px;
	background-color: #e0e0e0;
	height: 100%;
	left: -10px;
	top: 0;

}

.arrow-wrap {
	margin-top: -7px;
}

.map-bg {
	background-size: cover;
	height: 712px;
	padding-left: 30px;
	padding-right: 30px;
}

.map-bg .map-drop-down {
	line-height: 1.43;
	letter-spacing: 0.3px;
	font-size: 0.875rem;
	font-weight: 500;
	color: white;
	text-align: center;
	width: 100%;
	display: block;
	padding: 8px;
}

.card-body.map-bg {
	padding: 0px;
    position: relative;
    @media all and (max-width:767px){
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
}

.solar-community-box {
	background: #fff;
	padding: 30px 30px 10px 30px;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.26px;
	line-height: 20px;
	text-align: left;
	.share-via-wrapper.border-zero {
		&.share-sun{
			color: #00142D !important;
			font-style: italic;
		}
	}
	h6{
		font-size: 14px;
    	line-height: 20px;
    	letter-spacing: 0.33px;
	}
	h2{
		font-weight: normal;
		color: black;
		letter-spacing: 0.33px;
	}
	.community-icon{
		height: 33px;
		float: left;
		margin-right: 8px;
		&.logo{
			height: 55px;
			width: 63.75px;
			margin-left: 15px;
			margin-right: 16px!important;
		}
	}
	&.nav-header{
		padding: 40px 40px 30px 40px;;
	}
	
}

#collapseThree p {
	@include media-breakpoint-down(sm) {
		color: #000000;
		text-align: justify;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0.26px;
		line-height: 20px;
	}
}

#collapseThree label {
	@include media-breakpoint-down(sm) {
		color: #9B9B9B;
		
		font-size: 14px;
		letter-spacing: 0.26px;
		line-height: 16px;
		font-weight: normal;
	}
}





.more-detail-wrapper {
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	background-color: #ffffff;
	padding-top: 30px;
	padding-left: 20px;
	padding-right: 35px;
	padding-bottom: 30px;
	@include media-breakpoint-down(sm) {
		border: 0;
		padding-top: 0px;
	}
}

.more-detail-wrapper h3 {
	color: rgba(0, 0, 0, 0.87) !important;
	
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.23px;
	line-height: 21px;
	margin-bottom: 15px;
}

.more-detail-wrapper p {
	color: #000000;
	
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.25px;
	line-height: 20px;
	margin-bottom: 28px;
}

#collapseDetails.more-detail-wrapper p:last-child {
	@include media-breakpoint-down(sm) {
		margin-bottom: -30px;

	}
}

#collapseDetails.more-detail-wrapper {
	@include media-breakpoint-down(sm) {
		padding-top: 30px;
		padding-left: 15px;
	}
}

.more-detail-wrapper p span {
	font-weight: 500;
}

.border0 {
	border: 0;
}

.update-account {
	color: #000000 !important;
    letter-spacing: 1.17px;
    margin-left: 30px;
	@include media-breakpoint-down(sm) {
		margin-left: 22px;
	}
}

.update-acc-divider {
	background: #e0e0e0;
	height: 1px;
	border: none;
	margin-bottom: 8px;
	@include media-breakpoint-down(sm) {
		margin-top: 15px;
	}
}

.additional-user.block{
	.user{
		color: #4A4A4A;
		font-size: 14px;
		line-height: 20px;
		margin-top: 20px;
		img{
			height: 18px;
			margin-right: 15px;
			cursor: pointer;
		}
	}
	.add-user{
		cursor: pointer;
		margin: 15px 0;
		letter-spacing: 1.17px;

	}
}

.notification-lable {
	color: #000000 !important;
	letter-spacing: 1.17px;
	line-height: 24px !important;
	display: inherit;
	margin-top: 10px;
	margin-bottom: 0px;
	&.setting{
		margin-top: 20px;
    	margin-bottom: 15px;
	}
}

.notification-btn {
	display: flex;
	align-items: center;
	justify-content: center;
}

div.notification-btn{
	display: inline-block;
	&.referralReport{
		margin-left: 70px;
		@include media-breakpoint-down(sm) {
			margin-left :0
		}
	}
	&.homeowner{
		display: flex;
	}
	.ant-checkbox-inner{
		width: 19px;
		height: 19px;
		border-radius: 0;
	}
}


.home-owner-nav {
	opacity: 0.9;
	font-size: 16px;
	line-height: 21px;
	margin: 0 !important;
	cursor: pointer;
	width: auto;
	text-align: center;
	padding: 17px 15px 0px;
	&:hover{
		color: #4A4A4A;
	}
	.shareSun{
		font-weight: 500;
	}
	&.pad10{
		padding-top: 10px!important;
	}
	span:hover{
		border-bottom:2px solid ;
	}
}


.home-owner-active {
	font-weight: 500;
	span{
		border-bottom:2px solid ;
	}
}

.dashboard-heading {
	color: rgba(0, 0, 0, 0.87);
	
	font-size: 14px;
	letter-spacing: 0.25px;
	line-height: 20px;
	margin-top: 12px;
	margin-bottom: 20px;
}

.active-journey-progress {
	font-weight: 700 !important;
	@include media-breakpoint-down(sm) {
		padding-left: 15px;

	  }
}

.disabled-journey-progress {
	cursor: not-allowed;
	font-weight: 400 !important;
}

.journey-progress p:last-child {
    margin-bottom: 22px;
    @media all and (max-width:767px) {
        margin: 0;
    }
}

.ho-setting-acc-heading {
	
	font-size: 14px;
	letter-spacing: 0.25px;
	line-height: 20px;
	text-align: center;
}

// images grid
.grid-container {
	text-align: center;
	margin: auto;
	width: 100%;
	padding: 10px;
}

.container {

	width: 100% !important;
}

.container.images-grid {
	margin: 0;
	cursor: pointer;
}


.background {
	background-size: cover !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
}

.height-one {
	width: 100%;
	padding-top: 100%;
}

.height-two {
	width: 50%;
	padding-top: 50%;
}

.height-three {
	width: 33.3333%;
	padding-top: 33.3333%;
}

.cover {
	background-color: #222;
	opacity: 0.8;
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	border-radius: 6px;
}

.cover-text {
	right: 0;
	left: 0;
	bottom: 0;
	color: white;
	font-size: 7%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	transform: translate(0%, -50%);
	text-align: center;
}

.cover-text>p {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.slide {
	height: 0;
	bottom: 100%;
	transition: 0.5s ease;
	overflow: hidden;
	font-size: 3%;
}

.border:hover .slide {
	bottom: 0;
	height: auto;
}

.border:hover .animate-text {
	top: 62%;
}

.create-new-account-wrapper h2.dashboard-shedule-heading {
	font-size: 22px !important;
	font-weight: bold !important;
	line-height: 28px !important;
	text-align: left !important;
	padding: 0 !important;
	margin-top: 18px !important;
	margin-bottom: 16px !important;
	opacity: 1;
	text-transform: none;
}

.dashboard-shedule-text {
	font-size: 18px !important;
	font-weight: 500;
	line-height: 26px !important;
	text-align: justify;
	margin-bottom: 30px;
}

.dashboar-info-box {
	padding-left: 6px;
	padding-right: 6px;
	border-top: 2px solid;
	padding-top: 20px;
	.text {
		font-size: 14px;
		line-height: 16px;
		&.info{
			margin-right: 9px;
		}
	}
}

// donut chart
.chartCenterData {
	top: 46%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: black;
}
.kw-font{
	font-size: 18px;
}

.pM12 {
	top: 0%;
	left: 50%;
	transform: translate(-50%, -0%);
}

span.pM6 {
	top: 50%;
	right: 0%;
	transform: translate(58%, -50%);
}

.aM12 {
	bottom: 0%;
	left: 50%;
	transform: translate(-50%, -0%);
}

span.aM6 {
	top: 50%;
	left: 0%;
	-webkit-transform: translate(0%, -50%);
	transform: translate(-58%, -50%);
}

span.pM3 {
	top: 16%;
	right: 7%;
	transform: translateY(-25%);
}

span.aM9 {
	top: 16%;
	left: 7%;
	transform: translateY(-25%);
}

span.pM9 {
	bottom: 16%;
	right: 7%;
	transform: translateY(-25%);
}

span.aM3 {
	bottom: 16%;
	left: 7%;
	transform: translateY(-25%);
}

.wrapperChart svg {
	margin: auto;
}

.clockTime {
	color: gray;
	font-size: 12px;
}

.positionAbsolute {
	position: absolute;
}

.wrapperChart {
	position: relative;
	margin: auto;
}

.donut-path {
	stroke: none;
}


.docutment-row .col-sm-6:first-child img {
	width: 27px;
	@include media-breakpoint-down(sm) {
		width: 21px;
	}
}

.docutment-row .col-sm-6:nth-child(2) img {
	width: 47px;
	@include media-breakpoint-down(sm) {
		width: 32px;
	}
}

.docutment-row .col-sm-6:nth-child(3) img {
	width: 17px;
	@include media-breakpoint-down(sm) {
		width: 21px;
	}
}

.docutment-row .col-sm-6:nth-child(4) img {
	width: 57px;
	@include media-breakpoint-down(sm) {
		width: 40px;
	}
}

.docutment-row .col-sm-6:nth-child(5) img {
	width: 44px;
	@include media-breakpoint-down(sm) {
		width: 30px;
	}
}

.menu-box {
	height: 134px;
	border: solid 1px;
	border-color: #d8d8d8;
	box-sizing: border-box;
	font-weight: 300;
	text-align: center;
	font-size: 14px;
	letter-spacing: 0.26pt;
	line-height: 16pt;
	display: flex;
	flex-direction: column;
	justify-content: center;
	&.logout{
		margin: 0 auto;
		border: none;
	}
}

.menu-box img,.menu-box svg {
	display: block;
	margin: 0 auto;
}

.menu-mobile .menu-box:nth-child(even) {
	border-right: 0;
}

.menu-box .menu-text {
	display: inline-block;
	margin-top: 10px;
}

.menu-mobile .menu-box:nth-child(odd) {
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
}

.menu-mobile .menu-box:nth-child(5) {
	border-left: 0;
	border-right: 0;
	border-bottom: solid 1px #d8d8d8 !important;
}

.border-b-0 {
	border-bottom: 0;
}

.border-t-0 {
	border-top: 0;
}

.homeOwner-menu-mobile {
	overflow-y: hidden;
}

.home-owner-mobile-menu .navbar-collapse {
	@include media-breakpoint-down(md) {
		padding-top: 10px;
	}
}

.homerowner-view {
	padding-top: 35px !important;
	width: inherit !important;
	@include media-breakpoint-down(sm) {
		width: 100% !important;
		padding-top: 0 !important;
	}
}

.terms-text {
	margin: 0;
	padding: 0;
}

.terms-text li {
	font-size: 12px;
	font-weight: normal;
	line-height: 22px;
	letter-spacing: 0.22px;
	margin-bottom: 20px;
	list-style-position: outside;
	margin-left: 15px;
	font-weight: 300;
}

.copyright {
	text-align: center;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	letter-spacing: 0.22px;
	margin-bottom: 20px;
}



.paddt25 {
	padding-top: 25px;
}

.marg0 {
	margin: 0px;
}

.padd0 {
	padding: 0px;
}

.contact-wrapper {
	border: 1px solid #E0E0E0;
	border-radius: 4px;
	@include media-breakpoint-down(md) {
		border: none;
	}
}

.contact-wrapper textarea {
	background: $grey5;
	padding: $padd15;
	border: 1px solid $ltgrey3 !important;
	border-radius: 4px !important;
}

.slider-container{
    width: 100%;
    background: #fff;
	&.edResource{
		height: 270px !important;
	}
    .slider-wrapper {
		background-position:  center !important;  
        margin: auto;
        height: 234px;
        @media (max-width: 767px) {
            width: 100%;
            height: 210px;
        }
    }
}



.ant-slider-disabled .ant-slider-track, .ant-slider-track {
	background-color: #00142D !important;
}

.dots {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: #d8d8d8;
	display: inline-block;
}

.dots .active {
	background-color: #0ebdfa;
}

.ratingSlider {
	padding: 0px;
	list-style-type: none;
	display: flex;
	justify-content: space-evenly;
}

.ant-form-item-control{
	line-height: 20px;
}

.ratingSlider li .active {
	padding: 5px;
	background-color: #00142D;
	color: #fff;
}

.bg-light-homeowner {
	padding-top: 22px;
	border: 0 !important;
}

.mobile-view-only {
	display: none;
	@include media-breakpoint-down(sm) {
        display: inline;
        width: 100%;
    }
    @media all and (max-width:320px){
        zoom: 0.9;
    }
}

.mobile-view-only .white-box-secondary {
	@include media-breakpoint-down(sm) {
		border: 0;
	}
}

.desktop-view-only {
	display: block;
	@include media-breakpoint-down(sm) {
		display: none;
	}
}
.monitoring-desktop-view-only{
  @include media-breakpoint-down(sm) {
		display: none !important;
	}
}

.mobile-margin-zero{
	@include media-breakpoint-down(sm) {
		margin: 0 !important
	}
}
.homeowner-bg {
	background-color: #fff !important;
}

.calendar-box {
	text-align: center;
	font-size: 12px;
	font-weight: 300;
	color: #8e8b8b;
	height: 235px;
	.navigation-buttons{
		margin-bottom: 8px;
		.navigation.fa{
			cursor: pointer;
		}
		label.label-date{
			margin: 3px 14px;
		}
	}
	.rbc-month-view{
		border: none;
		.rbc-month-row{
			border: none;
		}
		.rbc-header{
			border: none;
		}
		.rbc-date-cell{
			text-align: center;
    		padding-top: 0;
    		color: #000;
    		padding: 0;
			margin-top: 7px;
			a{
				position: relative;
				z-index: 1;
			}
			&.rbc-off-range{
				opacity: 0;
				pointer-events: none;
			};
		}
	}
	.rbc-row-segment{
		text-align: center;
		padding-top: 0;
		color: #000;
		padding: 0;
		flex: 1 1;
		min-width: 0;
		.rbc-event{
			opacity: 0;		
			margin: 0;
			position: absolute;
			top: 2px;
			height: 28px;
			width: 10%;
			margin-left: 2.1%;
			padding: 0 !important;
			.rbc-event-content{
				height: 100%;
				white-space: normal;
				span{
					height: 100%;
					display: inline-block;
				
				}
			}
		}
	}
}

.date-in-past {
	width: 14.3%;
	&.stylecalender{
		background-color: #FFCC00;
		margin: .5% 2.5%!important;
    	flex-basis: 9.2857%!important;
        &._9{
            background-color: #FFD119;
        }
        &._8{
            background-color: #FFD633;
        }
        &._7{
            background-color: #FFDB4C;
        }
        &._6{
            background-color:  #FFE066;
        }
        &._5{
            background-color: #FFE57F;
        }
        &._4{
            background-color: #FFEB99;
        }
        &._3{
            background-color: #FFEFB2;
        }
        &._2{
            background-color: #FFF5CC;
        }
        &._1{
            background-color: #FFF9E5;
		}
		&._0{
            background-color: white;
		}
	}
	&.activeDate{
		background-color: #6eb4f5 !important;
	}
}

.energyToolTip{
	opacity: 1;
	.arrow{
		transform: translate(70px, 0px) !important;
		&::before{
			border-top-color:#25659c;;
		}
	}
	.tooltip-inner{
		padding: 0.5rem 1rem;
		.close-icon{
			height: 13px;
			position: absolute;
			right: 1px;
			top: 9px;
		}
	}
	
}

.logo-size-mobile {
	height: 29px;
	width: 18px;
}

.ratingSlider li {
	display: inline-block;
	width: 20%;
}

.ratingSlider li:first-child() {
	display: inline-block;
	width: 12%;
}

.ratingSlider li:nth-child(2) {
	display: inline-block;
	width: 12% !important;
}

.ratingSlider li:nth-child(3) {

	width: 38% !important;
	padding-left: 0px;
}

.ratingSlider li:nth-child(4) {

	width: 12% !important;
}

.ratingSlider li span {
	font-size: 10px;
	font-weight: 300;
	letter-spacing: 0.04px;
	line-height: 11px;
}

.rating-slider-btn {
	font-size: 14px !important;
	font-weight: 500 !important;
	letter-spacing: 0.26px !important;
	line-height: 15px !important;
	width:  auto !important;
	&.monitor{
		padding: 15px;
		line-height: 0 !important;
	}
}

.max-width-card-400-center {
	max-width: 400px;
	margin: 0 auto;
}

.max-width-card-380-center {
	max-width: 380px;
	margin: 0 auto;
}

.logo-size-mobile {
	height: 29px;
	width: 18px;
}
.document-collapse{
	font-weight: 300;
	font-size: 14px;
	letter-spacing: 0.26px;
	line-height: 28px;
	iframe{
		min-height: 0vh; 
		// border: none;
	}
	ul {
		margin: 0px;
		padding: 0px;
		li {
			list-style: none;
			text-transform: uppercase;
			padding: 10px 20px;
			word-break: break-all;
			@include media-breakpoint-down(sm) {
				
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 0.26px;
				line-height: 28px;
			}
			a {
				line-height: 2;
				letter-spacing: 0.3px;
				font-size: 0.875rem;
				color: black;
				font-weight: 300;
				&.row.collapsed.up-arra.active {
					color: #33ccff;
					font-weight: 500;
				}
			}
			.fa-angle-down {
				font-size: 1.375rem;
				color: #9b9b9b;
			}
			.fa-angle-up {
				font-size: 1.375rem;
				color: #9b9b9b;
			}
			
		}
	}
}

.react-calendar__month-view__days__day--neighboringMonth {
	visibility: hidden;
}

.react-calendar__month-view__days__day--weekend {
	color: inherit;
}

.react-calendar__navigation {
	height: 16px;
	color: #00142D;
	font-size: 14px;
	letter-spacing: 0.29px;
	line-height: 16px;
}

.installer-block-top-marg-mobile {
	margin-bottom: 30px;
	@include media-breakpoint-down(sm) {
		margin-top: 40px;
		margin-bottom: 0;
	}
}

.installer-block-top-marg-mobile.white-box,
.installer-block-top-marg-mobile .white-box {
	@include media-breakpoint-down(sm) {
		padding-bottom: 0 !important;
	}
}

.captalize-text {
	text-transform: capitalize !important;
}


.margb20-desktop {
	margin-bottom: 20px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
	}
}

.margb30-mobile {
	margin-bottom: 0px;
}


.share-comments {
	position: relative;
	margin-top: 15px;
	padding-top: 20px;
	padding-bottom: 25px;
	.share-link {
		top: 24px;
	}
	p {
		font-size: 0.875rem !important;
		padding-right: 40px;
		font-weight: 300 !important
	}
}


.energy-flow-wrap{
	.share-via-wrapper {
		@include media-breakpoint-down(sm) {
			border-radius: 0;
			border-left: 0 !important;
			border-right: 0 !important;
			border-bottom: 0 !important;
			margin-top: 0;
		}
	}
	p {
		font-size: 0.75rem;
		color: black;
		line-height: 1.43;
		letter-spacing: 0.3px;
		margin: 0px;
	}
	.slick-list{
		direction: rtl;
	}
} 

ul.mobile-app-calender-container {
	list-style: none;
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 20px;
	padding: 0;
}

.mobile-app-calender-date {
	letter-spacing: 1.25px;
    line-height: 16px;
	font-weight: 500;
    text-align: center;
	&.active {
		.ant-progress-circle-path{
			stroke-linecap: square;
		}
		.ant-progress-text{
			color: white !important;
		}
	}
	.day{
		margin-bottom: 7px;
		text-transform: uppercase;
	}
	.ant-progress-text{
		color: black !important;
	}
}

.slider-wrapper .date-arrow-right {
	position: absolute;
	top: 50%;
	right: 3%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(0, -50%);
	display: inline-block;
	content: "";
}

.slider-wrapper .date-arrow-right.date-arrow-left {
	left: 3%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(0, -50%) rotate(-180deg);
}

.date-arrow-right {
	background: url(../Assets/Images/arrow.svg);
	height: 28px;
	width: 28px;
	display: none !important;
}

.getCodeStyle {
	font-size: 14px !important;
	font-weight: 500 !important;
	letter-spacing: 0.26px !important;
	line-height: 16px !important;
	margin-top: 0;
}

.margin-bottom-20 {
	margin-bottom: 20px !important;
}

.progress-wrapper:nth-child(5) .row {
	position: relative;
}

.progress-wrapper:nth-child(5) .row:after {
	position: absolute;
	height: 1px;
	width: 87%;
	background: #aeb3c0;
	content: "";
	display: inline-block;
	max-width: 500px;
	bottom: -18px;
	left: 16px;
}

.saving-box,
.comapny-detail-box {
	@include media-breakpoint-down(sm) {
		padding-bottom: 20px !important;
		margin-bottom: 0 !important;
		border: 1px solid #e0e0e0;
		border-radius: 4px;
	}
}

.comapny-detail-box {
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0.25px;
		line-height: 20px;
	}
}

.page-title {
	display: none;
	@include media-breakpoint-down(sm) {
		display: block;
		color: #000000;
		
		font-size: 14px;
		letter-spacing: 1.17px;
		line-height: 24px;
		text-align: center;
		text-transform: uppercase;
		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 20px;
	}
}

.documents-title {
	@include media-breakpoint-down(sm) {
		margin-top: -15px;
	}
}

.document-box {
	border: 1px solid #c3d0dc;
}

.text-center.slider-heading {
	opacity: 0.9;
    font-size: 20px;
    font-weight: 500;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
	min-height: 45px;
    padding: 0 12px;
    margin: 0 0 15px;
    @media all and(max-width:767px) and (max-height:736px) and (orientation: portrait){
		min-height: 55px;
		height: auto;
		padding: 0px 12px;
		margin: 0;
    }
}

.DE-Flow {
	color: rgba(0, 0, 0, 0.87);
	
	font-size: 14px;
	letter-spacing: 0.25px;
	line-height: 20px;
}

.createBodhiAccount #exampleInputEmail1 {
	font-size: 14px
}


.margb10-m0bile {
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
	}
}

.solar-community-drop-down {
	position: absolute;
	top: 20px;
	width: 300px;
	z-index: 1;
	text-align: center;
	/* padding-left: 10px; */
	left: 50%;
	transform: translateX(-50%);
}

svg.donutChart {
	transform: rotate(180deg);
  fill-opacity: 1;
}



.referralThankyou-content {
	color: #000000;
	
	font-size: 18px;
	font-weight: 300;
	letter-spacing: 0.33px;
	line-height: 28px;
	text-align: center;
	margin-bottom: 0;
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0.26px;
		line-height: 20px;
		text-align: justify;
		margin-bottom: 20px;
		padding: 0 10px;
	}
	.referral-contact{
		display: block;
		@include media-breakpoint-down(sm) {
			display: inline;
		}
	}
}

.referral-submit {
	width: 100px;
}

.systemglanceLanding-image {
	background-repeat: no-repeat;
    width: 100%;
    height: auto;
	background-size: cover;
	background-position:center; 
	transition: all .5s ease;
	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 132px;
		height: 194px;
	}
}





.socialShare-outer+.pddl30,
.socialShare-outer+.pddr30 {
	@include media-breakpoint-down(sm) {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.label-center {
	width: 100%;
	@include media-breakpoint-down(sm) {
		width: auto;
	}
}

.login-form.homeowner-profile {
	@include media-breakpoint-down(sm) {
		padding-left: 0;
		padding-right: 0;
	}
}

.tab-content>.active {
	@include media-breakpoint-down(sm) {
		overflow-x: hidden;
	}
}

.section-from-box.newHomeOwnerProfile {
	@include media-breakpoint-down(sm) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.share-comments+.share-via-wrapper {
	float: none;
}

.message {
	pointer-events: none;
	@include media-breakpoint-down(sm) {
		pointer-events: all;
	}
	@include media-breakpoint-down(md) {
		pointer-events:all;
	}
}

.nav-item.dropdown {
	margin-right: 18px;
}

.system-glance img {
	width: 112px;
	height: 43px;
	margin-top: 22px;
	margin-left: 40px;
	@include media-breakpoint-down(sm) {
		width: 72px;
		height: 33px;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		margin-left: 0;
		top: 10px;
	}
}

.systMobleTitle {
	display: none;
	@include media-breakpoint-down(sm) {
		display: block;
		color: rgba(0, 0, 0, 0.87);
		
		font-size: 14px;
		letter-spacing: 0.25px;
		line-height: 20px;
		text-align: center;
		position: absolute;
		top: 90px;
		text-transform: uppercase;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}
}

.comapny-detail-box+.for-mobile {
	@include media-breakpoint-down(sm) {
		border: 1px solid #E0E0E0;
		border-radius: 4px;
		background-color: #FFFFFF;
		box-shadow: 0 2px 4px 0 #E9E9E9;
		margin-top: 13px;
		padding: 30px;
		margin-bottom: 60px;
		&.glance{
			margin-bottom: 0;
		}
	}
}

.comapny-detail-box+.for-mobile h4 {
	@include media-breakpoint-down(sm) {
		display: none;
		
	}
	&.glanceShare{
		@include media-breakpoint-down(sm) {
			display: block;
			font-weight: 700;
    		margin-top: 5px;
    		text-align: center !important;
		}
	}
}

.comapny-detail-box+.for-mobile p {
	@include media-breakpoint-down(sm) {
		color: #000000;
		
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0.26px;
		line-height: 22px;
		text-align: justify;
		padding: 0;
		margin: 0;
		margin-bottom: 5px;
	}
}

.for-mobile Form {
	lable {
		@include media-breakpoint-down(sm) {
			color: #9B9B9B;
			
			font-size: 14px;
			letter-spacing: 0.26px;
			line-height: 16px;
		}
	}
	.enter-your-ad.glance{
		margin: 10px 0px 10px;
		&.rating{
			margin-top: 25px;
		}
	}
}

.icon img {
	margin-left: 0;
}

.codeheading label,
.codeheading input,
.codeheading+.form-group,
.codeheading+.form-group+.form-group {
	@include media-breakpoint-down(sm) {
		margin-left: 12px;
	}
}

.poweredbysun {
	position: absolute;
	top: 46%;
	left: 50%;
	transform: translate(-50%, 50%);
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 0.25px;
	line-height: 17px;
	text-align: center;
	color: black;
}


.ant-select-arrow {
	right: 11px !important;
}

.editHomeOwnerState label {
	margin-bottom: 8px;
}

.editHomeOwnerState .select-state>div {
	background: #fff;
}
    
.sunburts-postion{
	right: -33%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);

	@include media-breakpoint-down(sm) {
		width:100%;
		padding: 0 15px;
		top: 50%;
	}
	&.glanceGraph{
		right: -26%;
		left: 50%;
	}
}
	
.sunburts-postion span.pM6{
	@include media-breakpoint-down(sm) {

	right: 1% !important;
}
}



	.sunburts-postion span.aM6 {
		@include media-breakpoint-down(sm) {		top: 50%;
		left: 1% !important;
	}
}





#homeownerview-mobile .homeownerFooter .footer-17{
	@include media-breakpoint-down(sm) {

	z-index: 0;
}
}
.document-collapse .file-error{
	color:#212529;
}
.folderList .file-error{
	color:rgba(0,0,0, .65)
}
.hideCommunity .solar-community-drop-down{
	@include media-breakpoint-down(sm) {

	z-index: 0;
}
}

.heading.ho-setting-heading.desktop-view-only {
	@include media-breakpoint-down(sm) {
		opacity: 0;
		display: block;
		padding-bottom: 0;
		height: 0;
}
}

.image-container {
	height: 85vh;
	text-align: center;
	padding: 20px;
	padding-bottom: 10px;
	img{
		height: auto;
		max-width: 550px;
		top: 50%;
		position: absolute;
		left: 50%;
		transform: translate(-50%,-50%);
		@include media-breakpoint-down(sm) {
			max-width: 85vw;
		}
	}
}


.landing-page-container {
	padding-top: 5%;
}
.ant-progress-circle .ant-progress-text {
	width: 90% !important;
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
p.bodhi-percent {
	color: rgba(0,0,0,0.87);
	margin-bottom: 0;
	font-size: 40px;
	line-height: 47px;
}
.to-being-solar-power {
	height: 40px;
	word-wrap: break-word;
	display: block;
	color: #4A4A4A;
	padding: 0 10px;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.25px;
	line-height: 20px;
	text-align: center;
}

.modal-status-feed .ant-modal-header {
	border: 0px;
}
.oval-2 {
	cursor: pointer;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	margin-left: 9px;
	background-color: #D8D8D8;
	position: absolute;
	right: -10px;
	top: 72px ;
	@include media-breakpoint-down(sm) {
		z-index: 99999;
		top: -20px;
		right: 34px;
		background-color: transparent;
		font-size: 25px;
	}
}

.oval-2-mobile {
	cursor: pointer;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	margin-left: 9px;
	background-color: #D8D8D8;
	position: absolute;
	right: 7px;
	top: -3px !important;
	@include media-breakpoint-down(sm) {
		z-index: 99999;
		top: 20px !important;
		right: 24px;
	}
}
.line-3 {
	box-sizing: border-box;
	margin-left: 9px;
}
.phase-survey-title {
	height: auto;
	color: #000000;
	
	font-size: 14px;
	letter-spacing: 1.17px;
	text-align: center;
	text-transform: uppercase;
}
.css-kj6f9i-menu, .css-kj6f9i-menu *{
	background:#fff !important;
	z-index: 999 !important;
	width: 100%;
}
.white-box-secondary.survey-modal {
	background-color: #F9F9F9;
	@include media-breakpoint-down(sm) {
		background-color: #FFF;	
		border: 0;
		overflow-x: hidden;
	}
}
.survey-media-modal {
	height: 100%;
	background: #fff;
	border-radius: 4px;
	border: 1px solid #E0E0E0;
	padding: 40px 25px;
}
.paddall25.col-sm-5 {
	padding: 10px 25px;
}
.pdding-left-zero{
		padding-left: 22px;
	
}
.pdding-right-zero{
		padding-right: 22px
}
.section-from-box.survey-modal-images {
	padding-bottom: 0px !important;
}
textarea.status-feed-message {
	background: #f9f9f9;
	padding: 15px;
	border: 1px solid #e0e0e0 !important;
	border-radius: 4px !important;
}
.status-feed-send {
	background: transparent;
	border: 0;
	height: 16px;
	width: 95px;	
	font-size: 14px !important;
	font-weight: 700;
	letter-spacing: 1.25px;
	line-height: 16px !important;
	text-align: right;
	display: inherit;
	margin-top: -42px;
	padding-right: inherit;
}
.status-feed-reply {
	height: 16px;
	width: 95px;
	// color: #00142D;
	
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.25px;
	line-height: 16px;
	text-align: right;
}
.media.share-comments {
	padding-bottom: 0px;
	margin-top: 0px;
	padding-top: 0px;
	padding-left: 0px;
    padding-right: 25px;
}

.create-new-account-wrapper.status-feed {
	width: 1010px;
	max-width: 100%;
	position: relative;
}
.white-box-secondary.survey-modal .survey-media-modal {
	@include media-breakpoint-down(sm) {
		border:0;
		padding: 20px;

	}}



.oval-4 {
	display: block;
  margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	}
.get-your-documents {
	height: 60px;	
	width: 990px;	
	color: #000000;	
		
	font-size: 18px;	
	font-weight: 300;	
	letter-spacing: 2.14px;
	text-align: center;
	text-transform: none;
	@include media-breakpoint-down(sm) {
		margin-left: 0;
		text-align: center; 
		margin: 0 auto;
		width: 100%;
	}
}
.get-your-documents-s {
	width: 990px;	
	color: #000000;	
		
	font-size: 25px;	
	font-weight: 400;	
	letter-spacing: 2.14px;
	text-align: center;
	@include media-breakpoint-down(sm) {
		margin-left: 0;
		text-align: center; 
		margin: 0 auto;
		width: 100%;
	}
}
.finance-doc {	
	height: 40px;	
	width: 950px;	
	color: #000000;	
		
	font-size: 18px;	
	font-weight: 300;	
	letter-spacing: 0.33px;	
	text-align: center;
	text-transform: none;
	@include media-breakpoint-down(sm) {
		margin-left: 0;
		text-align: center; 
		margin: 0 auto;
		width: 100%;
	}
}
.requestSignature{
	font-size: 14px !important;
	font-weight: 500 !important;
	letter-spacing: 0.26px !important;
	line-height: 16px !important;
	text-transform: uppercase;
	width: 202px;
	height: 25px;
	display: block;
  margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
}
.reviewSign{
	letter-spacing: 0.26px !important;
	display: block;
	margin: 20px auto 28px;
}
.reviewIcon{
	display: block;
  margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}
.reviewText{
		color: #595c5f;
		font-size: large;
		margin-left: 0;
		text-align: center; 
		margin: 0 auto;
		width: 100%;
	@include media-breakpoint-down(sm) {
		margin-left: 0;
		text-align: center; 
		margin: 0 auto;
		width: 100%;
	}
}
.ant-progress-circle-path{
	stroke-linecap: square;

}

.disabled-journey-progress.blacktext {
	color: #000000;
}

.padding-22 {
	padding: 22px !important;
}


.documents-button-status-feed{
	margin-top: 20px;
    margin-bottom: 5px;
	font-size: 14px !important;
	font-weight: 500 !important;
	letter-spacing: 0.26px !important;
	line-height: 16px !important;
}


.iframeMobile iframe{
	margin-left: 25px;
	@include media-breakpoint-down(sm) {
		margin-left: 0 !important;
	}
}

.status-feed-page-share {
	height: auto;
}

.share-link.status-feed-share {
	top: 0px;
}
.btn_views{
    justify-content: flex-end;
    margin-bottom: 5px;
    button{
        height: 26px;
        padding-left: 35px;
    }
    .edit-btn{
        background: url(./../Assets/Images/EditDetails.svg) no-repeat 0 0;
    }
}
.no-style{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
}

// Delete homeOwner
.delete_homeowner{
        position: relative;  
        color: #000000;
          
        margin-bottom: 100px;
    &-base{
        background: #fff;
        padding: 180px 80px 150px;
        h2{
            font-size: 24px;	
            letter-spacing: 2.14px;	
            line-height: 35px;
            font-weight: 300;
            position: relative;
            &:after{
                position: absolute;
                content: '';
                height: 83px;	
                width: 83px;	
                background: url(../Assets/Images/trash_yellow.svg) no-repeat center center;
                border-radius: 100%;
                right: 0;
                left: 0;
                top: -95px;
                margin: auto;
            }
        }
        h4{
            font-size: 18px;	
            font-weight: 300;	
            letter-spacing: 1.61px;	
            line-height: 28px;
            font-weight: 300;
        }
    }
    &-close{
        height: 40px;	
        width: 40px;	
        background-color: #D8D8D8;
        font-size: 18px;
        line-height: 40px;
        border-radius: 100%;
        position: absolute;
        top: -20px;
        right: -20px;
        cursor: pointer;
    }
}

.system_info{
    max-width: 330px;	
    
    padding-bottom: 50px;
    padding: 0;
    h4{
        color: #000000;	
        font-size: 14px;	
        font-weight: 300;	
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 12px;
        text-align: right;
        padding-right: 75px;
    }
    &-details{
        padding: 0 29px 0 0;
         >div{
            margin-bottom: 10px !important;
        }
        .form-wrap{
            width: 100% !important;
        }
        @media all and (max-width:767px){
            padding: 0 15px 0 0;
        }
    }
   
    label{
        	
        font-size: 14px;	
        font-weight: 300;	
        line-height: 16px;
    }
    input.ant-input{
        width: 61px !important;
        border-bottom: 1px solid #E9E9E9 !important;
        margin-left: 15px;
        float: right;
        height: 18px !important;
    }
}
.error_page_container{
    min-height: 100vh;
    background: #fff;
    &-logo{
        margin-bottom: 150px;
        border-top: 10px solid #fc0;
        padding: 15px 40px;
        img{
            width: 112px;
        }
    }
    &-404image{
        padding-right: 95px;    
        position: relative;
        @media all and (max-width:767px){
            padding-right: 75px;
            text-align: center;
        }
    }
    &-cloud_wrap{
        position: relative;
        width: 220px;
        @media all and (max-width:767px){
            margin: auto;
        }
        img{
            position: relative;
            z-index: 1;
        }
        &:after{
            position: absolute;
            content: '';
            background: url(../Assets/Images/sun.svg) no-repeat center center/contain;
            width: 123px;
            height: 123px;
            right: -30px;
            top: -20px;
        }   
        
    }
    h4{
        font-size: 23px;	
        font-weight: 300;	
        line-height: 23px;
        color: #000000;	
        
    }	    
    h2{
        font-size: 58px;
        line-height: 58px;
        font-weight: 300;
        color: #000000;	
        
    }
    &-content{
        @media all and (max-width:767px){
            text-align: center;
            margin-top: 40px;
        }
    }
}
.back-to-dash{
    height: 45px;	
    width: 250px;	
    border: 1px solid #FFCC00;	
    background-color: #FFFFFF;
    color: #FFCC00;	
    	
    font-size: 18px;	
    font-weight: 500;	
    line-height: 21px;
    text-transform: uppercase;
}
.ant-select-disabled .ant-select-selection{
	cursor: not-allowed !important;
	background-color: white !important ;
}
.ant-input[disabled]{
	background-color: white !important ;
	color: black !important;
}
.col-md-6.resources-wrapp {
	@media all and (max-width:767px){
	padding: 0px;
	}
}
.resources-wrapp .resource-box .resource-bg.complete {
	@media all and (max-width:767px){
	margin-top: 0;
	height: 100vh;
	}
}
.resources-wrapp .resource-box .resource-bg.upcoming {
	@media all and (max-width:767px){
	margin-top: 0;
	height: 100vh;
	}
}
.resources-wrapp .resource-box .resource-bg.complete .slick-slider .slick-track {
	@media all and (max-width:767px){
	width: 103% !important;
	margin: 0px;
	}
}
.resources-wrapp .resource-box .resource-bg.upcoming .slick-slider .slick-track {
	@media all and (max-width:767px){
	width: 103% !important;
	margin: 0px;
	}
}
.resources-wrapp .resource-box .resource-bg.complete .slick-initialized .slick-slide:last-child {
	@media all and (max-width:767px){
	border: 0px;
	width: 100% !important;
	}
}

.resources-wrapp .resource-box .resource-bg.upcoming .slick-initialized .slick-slide:last-child {
	@media all and (max-width:767px){
	border: 0px;
	width: 100% !important;
	}
}



.container .create-new-account-wrapper-new {
	@media all and (max-width:767px){
		padding-bottom: 0px !important;
	}
}
.margin-t-73-new {
	@media all and (max-width:767px){
		margin-top: 0px !important;
	}
}
.group-2  {
	height: 30px;	
	width: 27.77px;
	margin-right: 10px;
}

.share-the-sun {
	height: 21px;	
	font-size: 18px;
	font-weight: bold;
	line-height: 21px;
	padding-left: 5px;
	
}
.invite-box {
	width: 90%;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 30px;
	&.col{
		color: #000000;
		p {
			font-size: 12px;
			text-align: center;
			margin-bottom: 10px;
		}
	}
}
.invite-box p {
	font-size: 12px;
	text-align: center;
	margin-bottom: 10px;
}
.invite-box a{
	margin: 0 5px;
}
.map-bg .share-via-wrapper .heading-share {
	color:#00142D;
	font-size: 14px;
	font-style: italic;
	margin-top:20px;
}
#share-media-box {
	margin-top: 20px;
	padding: 0px;
	border: 0 !important;
}
.form-detail-survey {
	@media all and (max-width:767px) {
	margin: 0 auto;
	height: 225px;
	width: 235px;
	margin-top:30px;
	
}
	
}
.form-boxx{
margin-top: 18px;
margin-bottom: 18px;
}

.date-reply-box .ant-form {
	width: 90%;
	margin: 0 auto;
	margin-top: 20px;
}
.date-reply-box .yellow-btn {
    margin-top: 10px;
    margin-bottom: 20px;
}

.your-progress-wrap {
	width: 85%;
    margin: 0 auto;
}
.password-reset-form-wrap{
	width: 65%;
	margin: 0 auto;
}


.footer-btn-contactMe{
	margin-top: -$marg50;
}
.lifetimeSaving{
height: 38px;	
width: 75px;	
color: #00142D;	
	
font-size: 32px;	
font-weight: bold;	
line-height: 30px;	
text-align: center;
}
.center-icon .icon{
	text-align: center;
	min-height: 40px;
	margin-top: 0px;
}
.center-icon-value .icon{
	margin:10px 0 auto;
	min-height: 56px;
	margin-top: 0;
}

@media all and (max-width:767px) {
	.center-icon-value .icon {
		font-size: 30px;
		min-height: 40px;
		margin-top: 0;
	}
}

.ant-slider-mark{
	left:5%
}


.calc-gap{
	.ant-slider-mark-text .sunburst-slider.mobile-color {
		@media (max-width: 767.98px){
			padding-right:20px;
	}
}

}
.comp-icon{
	border-radius: 0!important;
	margin: 0!important;
	height: 48px!important;	
	width: 48px;
}


.pad-left{
	padding-left: 13px !important;
}

.shareSun-modal{
	.ant-modal-content{
		border-radius: 0;
	}
	.anticon svg{
		height: 24px;
		width: 24px;
	}
	.sharethesun-field{
		height: 20px;
		width: 95px;
		color: #4A4A4A;
		
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0.26px;
		line-height: 16px
	  }
	  .sharethesun-field-pad{
		  padding: 0px 70px;
	  
	  }
	  .sharethesun-give
	  {
		height: 64px;
		width: 282px;
		color: #000000;
		
		font-size: 24px;
		letter-spacing: 0.45px;
		line-height: 32px;
	  }
	  .sharethesun-content{
		margin-bottom: 20px;
		height: auto;
		width: 352px;
		color: #000000;
		
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 20px;
		text-align: justify;
		margin-left: 20px;
	  }
}

.chartWrapper-tooltip{
	position: absolute;
    background-color: whitesmoke;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 2;
    font-weight: bold;
    border: 1px solid #d6cbcb;
	letter-spacing: 1px;
	display: none;
}

.ant-select-dropdown{
	box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05) !important;
}

.premiumButtonFill{
	&:hover{
		background-color: #AAAAAA !important;
	}
	&.notHover{
		border-radius: 0 !important;
	}
}

.reversePremiumButtonFill{
	color: black  !important;
	&:hover{
		background-color: #AAAAAA !important;
		border-color: #AAAAAA !important;
	}
}

.scanifly{
	text-align: center;
	margin-bottom: 10px;
	.overlay{
		width: 100%;
    	height: 365px;
    	position: relative;
    	margin-top: -365px;
		cursor: pointer;
		&.statusFeed{
			height: 475px;
			margin-top: -475px;
		}
		&.glance{
			height: 175px;
			margin-top: -175px;
		}
	}
	
	.expand{
		color: #fff;
    	background: rgb(0,0,0);
    	width: 20%;
    	height: 26px;
    	margin-top: -34px;
		float: right;
    	position: relative;
		&.statusFeed{
			right: 18px;
			bottom: 7px;
			margin: 0;
			position: absolute;
			@include media-breakpoint-down(md) {
				right: 25px;
			}
		}
		@include media-breakpoint-down(md) {
			// margin-right: -8px;
		}
	}
}
.scanifly-text{
	padding: 0 15px;
	text-align: justify;
	margin: 0;
}

.box-Shadow{
	border: 1px solid #E0E0E0;
	box-shadow: 0 2px 6px 0 rgba(176,186,197,0.50) !important;
}

.black-tooltip{
	.ant-tooltip-inner{
		background-color: #00142d;
		color: #ffd110;
		font-weight: 400;
	}
	.ant-tooltip-arrow::before{
		background-color: #00142d;
	}
}

.yellow-tooltip{
	.ant-tooltip-inner{
		background-color: #ffd110;
		color: #00142d;
		font-weight: 400;
	}
	.ant-tooltip-arrow::before{
		background-color: #ffd110;
	}
}

.ant-select-open  svg{
	transform: rotate(180deg);
	transition: transform 0.3s, -webkit-transform 0.3s;
	margin-top: 3px;
}

.ant-select-arrow svg {
    transition: transform 0.3s, -webkit-transform 0.3s;
	margin-top: 3px ;
}
