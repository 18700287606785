.create-new-account-wrapper {
	width: 827px;
	margin: 0 auto;
	margin-top: $marg86;
	margin-bottom: $marg80;
	@include media-breakpoint-down(sm) {
		margin-top: $marg40;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
	}
}

.create-new-account-box {
	padding: $padd40;
	height: 536px;
	@include media-breakpoint-down(md) {
		padding: $padd0;
		background: transparent;
		border: 0;
	}
}



.new-install-box {
	display: flex;
    flex-direction: column;
	@include media-breakpoint-down(md) {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}
.new-install-box h4{
	margin-top: 38px;    
	margin-bottom: 0;
	color: #000000;		
	font-size: 24px;	
	line-height: 28px;
	@include media-breakpoint-down(sm) {
	color: #000000;		font-size: 20px; line-height: 24px; 	margin-top: 16px;
	}
}

.col-sm-6.text-center.new-install-box.hori-vert-cetner:after {
	@include media-breakpoint-down(md) {
		content: "or";
		position: absolute;
		bottom: -70px;
		padding: 10px;
		font-size: 16px;
		z-index: 3;
		background: #fff;
	}
}

.col-sm-6.text-center.new-install-box.hori-vert-cetner:before {
	@include media-breakpoint-down(md) {
		content: "";
		border-top: solid 1px #e9e9e9;
		position: absolute;
		width: 100%;
		bottom: -50px;
		z-index: 1;
	}
}


.create-new-account-wrapper h2 {
	
	margin-bottom: 35px;
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		margin-bottom: $marg40;
		text-align: left;
	}
}
.slider-2-content{
	color: #FFFFFF;		
	font-size: 18px !important;	
	line-height: 22px !important;	
	text-align:center; 
	padding-top:35px !important; 
	margin-bottom:88px !important;     
	height: auto;
    min-height: 150px;

}

.create-new-account-box img {
	margin-bottom: $marg30;
}

.create-new-account-box .col-sm-6 a {
	font-size: $font24;
	font-weight: normal;
	color: black;
	text-transform: uppercase;
}