.salesformce-wrapper.header-wrapper {
	background-size: cover;
	height: 560px;
	color: #320000;
	font-size: 18px;
	line-height: 26px;
	letter-spacing: 0.33px;
}
.salesformce-wrapper {
	.logo-box {
		display: block;
		width: 110px;
		padding: 25px 54px;
		.logo {
			img {
				width: 110px;
				height: 43px;
				display: block;
			}
		}
	}
	h1 {
		font-size: 32px;
		line-height: 38px;
		color: #320000;
		text-align: center;
		font-weight: 800;
	}
	.steps {
		color: #00142D;
		width: 411px;
		margin: 50px auto;
		a {
			color: #00142D;
		}
	}
	h2 {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 24px;
		line-height: 28px;
		font-weight: 800;
	}
	.round-number {
		background: #00142D;
		width: 38px;
		height: 38px;
		font-size: 24px;
		line-height: 28px;
		color: #ffffff;
		border-radius: 50%;
		display: inline-block;
		margin-right: 20px;
		padding-top: 5px;
		text-align: center;
		font-weight: 500;
	}
}
.img-box {
	margin-top: 40px;
	margin-left: 30px;
	margin-bottom: 40px;
	img {
		max-width: 746px;
		width: 100%;
		padding: 10px;
		border: 1px solid #9b9b9b;
	}
}
.list-view {
	margin-top: 30px;
	padding-left: 16px;
	li {
		padding-bottom: 6px;
	}
}
.p-box {
	a {
		word-break: break-all;
	}
	padding-left: 58px;
}
.ml-0 {
	margin-left: 0;
	margin-left: 0;
}



.list-style {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
}
.view-call-back {
	padding-left: 25px;
}
.add-oth {
	padding-left: 12px;
}
.ml-10-0 {
	margin-left: -16px !important;
}
@media screen and (max-width: 767px) {
	.salesformce-wrapper {
		.steps {
			width: 100%;
		}
		.white-box {
			padding: 40px 15px;
			margin: 30px 0;
		}
		.round-number {
			font-size: 20px;
			width: 30px;
			height: 28px;
			padding-top: 0;
			margin-right: 10px;
		}
		h2 {
			font-size: 20px;
		}
		h1 {
			font-size: 25px;
			line-height: 30px;
		}
		.logo-box {
			padding: 15px;
		}
	}
	.p-box {
		padding-left: 43px;
	}
	.img-box {
		margin-left: 0;
	}
}
