
.sys-notification-wrapper {
	.left-menu-box {
		position: relative;
		padding: 42px 30px 30px 62px;
		&::after {
			position: absolute;
			content: '';
			height: 100%;
			width: 2px;
			background-color: #F5F8FB;
			top: 0;
			right: 0;
			text-align: left;
		}
	}
	h2 {
		font-size: 18px;
		line-height: 20px;
		color: #6A6A6A;
		border-bottom: 1px solid #E0E0E0;
		padding: 10px 0;
		img {
			margin-right: 10px;
		}
	}
	.left-menu {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			color: #6A6A6A;
			font-size: 14px;
			line-height: 18px;
			padding: 10px 0;
			font-weight: 700;
			a {
				color: #6A6A6A;
				&:hover {
					color: #00142D;
				}
				.collpaseble{
					margin-right: 5px;
					width: 10px;
					margin-bottom: 3px;
					height: 7px;
					&.rotate{
						transform: rotate(90deg);
					}
				}
			}
			a.active {
				color: #00142D;
			}
		}
	}
	.right-content-box {
		h2 {
			color: #00142D;
			font-size: 24px;
			line-height: 20px;
			// margin-bottom: 32px;
		}
		.content-box {
			border: 1px solid #E0E0E0;
			.trigger-box {
				color: #000;
				line-height: 18px;
				padding: 5px 15px 0;
				border-bottom: 1px solid #DFE6EE;
				.phase-name{
					width: 60%;
					padding: 0 5px;
					font-weight: 400;
					letter-spacing: 0.5px;
				}
				.edit-icon{
					height: 17px;
    				margin: 0 7px;
				}
			}
			.content-section-box {
				font-size: 14px;
				line-height: 18px;
				width: 450px;
				margin: 0 auto;
				padding: 10px 10px 40px 10px;
				.form-control {
					border-left: 0;
					border-right: 0;
					border-top: 0;
					border-radius: 0;
					box-shadow: none;
					max-width: 61px;
					font-size: 14px;
					line-height: 18px;
					&:focus {
						border-left: 0;
						border-right: 0;
						border-top: 0;
						border-radius: 0;
						box-shadow: none;
						max-width: 61px;
						font-size: 14px;
						line-height: 18px;
					}
				}
			}
		}
	}
}

.journey-option{
	label{
		display: block !important;
		margin: 15px 0;
	}
}

.my-handle{
	cursor: move;
	cursor: -webkit-grabbing;
	font-size: 21px;
	margin-right: 10px;
}

.p0 {
	padding: 0;
}
.tabs-wrapper {
	.tab-content {
		>.active {
			display: block;
			opacity: 1;
		}
	}
	.nav-tabs {
		background-color: #f4f7fa;
		.nav-item {
			flex-grow: 1;
			text-align: center;
			font-size: 14px;
			line-height: 16px;
			font-weight: bold;
		}
		.nav-link {
			border: 0;
			cursor: pointer;
		}

		.nav-item.show {
			.nav-link {
				border-radius: 0;
				color: #00142D;
			}
		}
		.nav-link.active {
			border-radius: 0;
			color: #ff6f47;
		}
	}
}
.mb0 {
	margin: 0;
}
.tab-content-wrapper {
	padding: 40px 15px 15px 15px;
	.filter-select {
			&::placeholder { 
				color: #BFBFBF;
			  }
			  
			&:-ms-input-placeholder {
				color: #BFBFBF;
			}
			
			&.personlize{
				color: #00142D;
			}
		.ant-select-selection__rendered{
			.ant-select-search__field{
				width: 100% !important;
				border: none !important;
			}
		}
	}

	.status-feed-option label.ant-radio-wrapper{
		width: 100%;
		font-weight: 300;
	}
	
	select {
		font-size: 14px;
		line-height: 18px;
		border-radius: 0;
		box-shadow: none;
		border-color: #ced4da;
		padding: 0px 15px;
		height: 25px;
		&:focus {
			font-size: 14px;
			line-height: 18px;
			border-radius: 0;
			box-shadow: none;
			border-color: #ced4da;
			padding: 0px 15px;
			height: 25px;
		}
	}
}
.add-new-box {
	padding: 0 15px 0 15px;
	color: #00142D;
	a {
		color: #00142D;
		font-size: 14px;
		line-height: 14px;
		&.disable-link{
			pointer-events: none;
		}
	}
}
.add-trigger{
	width: 15px;
    margin-right: 6px;
}
.plus {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	text-align: center;
	line-height: 15px;
    margin-right: 5px;   
	cursor: pointer;
}
.delete-heading {
	text-align: right;
	float: right;
	color: #6A6A6A;
	font-size: 14px;
	line-height: 14px;
	cursor: pointer;
	margin-top: 5px;
	a {
		color: #6A6A6A;
	}
	img {
		margin-right: 6px;
	}
}
.border-box-add {
	text-align: center;
	color: #00142D;
	font-size: 14px;
	line-height: 16px;
	width: 100px;
	margin: auto;
	margin-top: 23px;
	margin-bottom: 23px;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		height: 24px;
		width: 1px;
		background-color: #E0E0E0;
		top: 0;
		left: 0;
		top: -24px;
		left: 10px;
	}
	&::before {
		content: '';
		position: absolute;
		height: 24px;
		width: 1px;
		background-color: #E0E0E0;
		left: 0;
		bottom: -24px;
		left: 10px;
	}
	.add-step {
		width: 20px;
		margin-right: 5px;
		cursor: pointer;
		margin-left: -10px;
	}
}
.tab-content {
	.container {
		padding-bottom: 30px;
	}
}
.border-textarea {
	border: 1px solid grey;
	border: 1px solid #D8D8D8;
}
.form-box-wrapper {
	.tab-content-wrapper {
		form {	
			width: 100%;
		}
		input[type=text] {
			border-radius: 0;
			width: 100%;
			border-color: #D8D8D8;
			font-size: 14px;
			line-height: 14px;
			color: #4A4A4A !important;
		}
		input[type=email] {
			border-radius: 0;
			width: 100%;
			border-color: #D8D8D8;
			font-size: 14px;
			line-height: 14px;
			color: #4A4A4A !important;
		}
		textarea {
			border: 1px solid lightgray !important;
			width: 100%;
			font-size: 14px;
			line-height: 14px;
			color: #4A4A4A !important;
			padding: 10px 8px;
		}
		select {
			border: 0;
			width: auto;
			color: #00142D;
			font-size: 14px;
			line-height: 14px;
			margin-bottom: 5px;
		}
	}
	label {
		font-size: 14px;
		line-height: 14px;
		color: #4A4A4A !important;
		&.form-check-label{
			font-weight: 300;
		}
	}
}
.check-box-wrapper {
	padding-top: 20px;
	.button-tab-detail{
		margin-left: 20px;
    	margin-top: 3px;
		.button-name{
			padding: 0;
		}
		.filter-select{
			width: 145px !important;
		}
	}
}

.popUp{
	&.ant-modal{
		width: 622px !important;
		color: #000 !important;
	}
	.ant-modal-body{
		padding: 70px 0px 10px;;
		text-align: center;
		.high-five{
			height: 50px;
			width: 62.03px;
		}
		.header{
			margin: 25px auto 10px;
    		width: 70%;
		}
		p{
			font-size: 16px;
		}
		.row{
			margin: 0;
			margin-top: 43px;
			.form-group.edit-icon-box{
				margin: 0 50px;
				span.pen-icon{
					position: absolute;
    				right: 50px;
    				top: 50%;
				}
			}
		}
	}
	.ant-modal-footer{
		border: none;
		padding-bottom: 81px;
		text-align: center;
	}
}