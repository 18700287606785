.plus-icon {
	font-weight: 600;
	padding-right: 3px;
}
.internal-cmn-form {
	border-bottom: 3px solid #F5F8FB !important;
	padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 10px;
	.form-heading {
		color: #000 !important;
		font-size: 18px  !important;
		line-height: 28px;
		margin: 0;
		font-weight: 300;
		border: none;
		margin-bottom: 30px !important;
	}
	.cloud-upload-img{
		position: relative;
		background-size: 20px 20px;
		display: inline-block;
    	width: 20px;
    	height: 20px;
    	margin-right: 5px;
    	margin-left: 10px;
		background-image: url("../Assets//Images/add_cloud.svg");
		cursor: pointer;
	}
	.file-upload{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}
	
	.add-company-label{
		margin-bottom: 12px;
    	margin-top: 16px;
	}
	.delete{
		cursor: pointer;
		line-height: 30px;
		.fa.fa-trash{
			color: red;
			margin-right: 6px;
			float: left;
		}
	}
}
.lbl-cmn-field {
	text-transform: capitalize;
    color: #000 !important;
    line-height: 16px;
    padding-bottom: 0;
    margin: 15px 0px;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 300;
	width: 100%;
	display: block;
	&.textNone{
		text-transform: none;
	}
	&.dynamicFont{
		font-size: 40px;
		margin-top: 32px;
    	line-height: inherit;
	}
	&.source{
		font-family: 'Source Sans Pro', sans-serif!important;
	}
	&.rubik{
		font-family: 'Rubik', sans-serif !important;
	}
	&.raleway{
		font-family: 'Raleway', sans-serif !important;
	}
	&.questrial{
		font-family: 'Questrial', sans-serif !important;
	}
	&.poppins{
		font-family: 'Poppins', sans-serif !important;
	}
	&.montserrat{
		font-family: 'Montserrat', sans-serif !important;
	}
	&.lato{
		font-family: 'Lato', sans-serif !important;
	}
	&.kulim{
		font-family: 'Kulim Park', sans-serif !important;
	}
	&.cabin{
		font-family: 'Cabin', sans-serif !important;
	}
	&.harmonia{
		font-family: 'HarmoniaSansProCyrRegular'!important;                                        
	}
	&.gothic{
		font-family: 'Century Gothic', sans-serif !important;                                        
	}
	// &.futura{
	// 	font-family: 'Futura Std' !important;
	// }
	&.proxima{
		font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif !important;
	}
	&.kulim{
		font-family: 'Kulim Park', sans-serif !important;
	}
	&.muli{
		font-family: "muli",sans-serif; 
	}
	&.arial{
		font-family: 'Arial', Helvetica, sans-serif;
	}
}

.form-submitted-btn {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 30px;
}
.utility-tab-main-pnl {
	border: 1px solid #E0E0E0;
}
.utility-inner-pnl {
	ul {
		li {
			width: 50%;
			a {
				font-size: 14px;
				line-height: 16px;
				font-weight: 600;
				background: #f4f7fa;
				width: 100%;
				width: 100%;
				display: block;
				border: 1px solid #e9e9e9 !important;
				height: 40px;
				border-radius: 0 !important;
				line-height: 22px;
				text-align: center;
				border-top: 0 !important;
				border-left: 0 !important;
			}
			a.active {
				color: #ff6f47 !important;
				background: #fff !important;
			}
		}
	}
}
.filter-div-pnl {
	display: inline-block;
    width: 50%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #ffffff;
    margin-right: 0;
    color: black;
    font-size: 14px;
    font-weight: bold;
}
.addutility-btn {
	float: right;
}
.filter-1 {
	font-size: 14px;
	line-height: 16px;
	padding-right: 10px;
	color: #9b9b9b !important;
	cursor: pointer;
}
.filter-2 {
	color: #9b9b9b !important;
	font-size: 14px;
	line-height: 16px;
	padding-right: 10px;
	cursor: pointer;
}

.cmn-table-format {
	table {
		border: none !important;
		tr {
			td {
				text-transform: capitalize;
				color: #4A4A4A !important;
				font-size: 14px;
				width: 50%;
				line-height: 16px;
				padding: 0.75rem 0.75rem 0.75rem 3rem;
				i {
					color: #c1c1c1 !important;
					margin-right: 15px;
					cursor: pointer;
				}
				.icon{
					float: right;
					height: 17px;
					margin-right: 15px;
					cursor: pointer;
					margin-top: 12px;
				}
			}
		}
	}
}
.right-alignment {
	text-align: right;
	.action-icon{
		height: 15px;
		cursor: pointer;
	}
}
.cmn-modal-dialog {
	max-width: 850px;
}
.cmn-modal-header {
	text-align: center;
	align-items: center;
	justify-content: center;
	border: none;
	.modal-title-logo {
		width: 45px;
		margin-right: 15px;
		&.eversign{
			width: 35px;
			margin-right: 10px;
		}
	}
}
.cmn-modal-body {
	padding-top: 50px;
	padding-bottom: 50px;
}
.justify-center-content {
	justify-content: center;
}

.plus-add-unity {
	font-size: 20px;
	font-weight: 600;
	cursor: pointer;
	color: #00142D;
	display: inline;
	margin-left: 13px;
	&.hide {
		opacity: 0;
	}
	&.space {
		margin-left: 25px;
	}
}

/**
 * Start: general overrides for antd forms, version 4
 */
.ant-input-affix-wrapper {
    border: 0 none;
    padding: 0;
}
/**
 * End: general overrides for antd forms, version 4
 */

/**
 * Start: Updated styles for inline, dynamic antd forms, e.g. +/- elements
 */
.inline-85-percent {
	width: 85%;
}

.inline-65-percent {
	width: 65%;
}

.form-el-dyn-add {
	position: relative;
	padding: 1rem;
	a.plus-add-unity {
		position: absolute;
		right: 2rem;
		top: 1rem;
		margin: auto;
	}
	/**
	 * Override the default styles for the antd node
	 */
	.ant-input-affix-wrapper {
		border: 0 none;
	}
}
/**
 * End: Updated styles for inline, dynamic antd forms, e.g. +/- elements
 */


.space-bottom {
	padding-bottom: 30px;
}
.inner-reports-owner {
	margin-top: 170px;
}
.reports-inner-pnl {
	background: #fff;
	padding: 30px 40px;
	.form-heading2 {
		text-transform: capitalize;
		color: #4A4A4A !important;
		font-size: 24px;
		letter-spacing: 2px;
		line-height: 28px;
		margin: 0;
		font-weight: 300;
		padding-bottom: 30px;
	}
}

.map-address-box{
	position: absolute;
    height: 40px;
    width: 450px;
    border: 1px solid #E9E9E9;
    background-color: #FFFFFF;
    z-index: 5;
    top: 125px;
	left: 43px;
	input{
		text-overflow: ellipsis;
		height: 100%;
		padding: 0 10px;
		width: calc(100% - 35px);
	}
	.fa-search{
		color: #00142D;
		cursor: pointer;
	}
}
